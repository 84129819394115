import { IonCol, IonRow } from '@ionic/react';
import { Backdrop } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Copyright from '../../../components/Copyright/Copyright';
import LoginForm from '../../../components/LoginForm/LoginForm';
import LoginModal from '../../../components/Modal/LoginModal';
import { LIVE_WEB_CASINO1 } from '../../../constants/CasinoView';
import { RootState } from '../../../models/RootState';
import { GapGame } from '../../../models/dc/DcGame';
import { FatchLiveCasinoGames } from '../../../store';
import './CasinoNew.scss';
import GetMinAndMaxValues from './GetMinAndMaxValues';

type StoreProps = {
  loggedIn: boolean;
  loggedInUserStatus: any;
  FatchLiveCasinoGames: (type: string) => void;
  liveCasinoGames: GapGame[];
  scroll: boolean;
};

const VirtualSports: React.FC<StoreProps> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const {
    loggedIn,
    loggedInUserStatus,
    FatchLiveCasinoGames,
    liveCasinoGames,
    scroll
  } = props;
  const pathParams = useParams();
  const selectdGame = pathParams['game'];
  const history = useHistory();
  const [virtualList, setVirtualList] = useState<GapGame[]>([]);
  const setDialogShow = (show: boolean) => {
    setShowDialog(show);
  };
  const divRef: any = useRef();
  const scrollToElement = () => {
    const current: any = divRef;
    if (current !== null){
        current?.current?.scrollIntoView({behavior: "smooth"})
    }
  }

  useEffect(() => {
    scrollToElement();
  }, [scroll])

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedInUserStatus === 0 || loggedInUserStatus === 3) {
      history.push(`/home`);
    }
    if (provider === 'Indian Casino') {
      history.push(`/casino/indian/${gameCode}`);
    } else {
      history.push(
        `/gap_casino/game/${gameName.toLowerCase().replace(/\s+/g, '-')}-${btoa(
          gameId.toString()
        )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
      );
    }
  };

  const handleGameClick = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider?: string
  ) => {
    if (loggedIn) {
      getGameUrl(gameId, gameName, gameCode, provider, subProvider);
    } else {
      setDialogShow(true);
    }
  };

  useEffect(() => {
    FatchLiveCasinoGames('virtual');
  }, []);
  // useEffect(() => {
  //   if (selectdGame === 'sports') {
  //     let virtualGamesList = liveCasinoGames.filter(
  //       (g) =>
  //         g.category === 'VIRTUAL' && g.providerName?.toLowerCase() === 'vsbr'
  //     );
  //     setVirtualList(virtualGamesList);
  //   } else {
  //     let virtualGamesList = liveCasinoGames.filter(
  //       (g) => g.category === 'VIRTUAL'
  //     );
  //     setVirtualList(virtualGamesList);
  //   }
  // }, [selectdGame]);

  useEffect(() => {
    if (liveCasinoGames?.length > 0) {
      let virtualGames = liveCasinoGames.filter(i => i.category.toLowerCase() == "virtual" &&
        i.subProviderName.toLocaleLowerCase() !== "virtual sports" &&
        i.gameName.toLocaleLowerCase() !== "virtual sports");
      setVirtualList(virtualGames)
    }
  }, [liveCasinoGames])

  return (
    <div ref={divRef} className="dc-page-bg">
      <div className="dc-ctn">
        <IonRow class="casino-filters-row">
          <div className="title">Virtual games</div>
        </IonRow>
        <div className="title-border-line"></div>

        {/* {virtualList && virtualList.length > 0 ? ( */}
        {virtualList && virtualList.length > 0 ? (
          <>
            <IonRow className="dc-games-ctn">
              {/* {virtualList.map((g, i) => ( */}
              {virtualList.map((g, i) => (
                <IonCol
                  sizeXs="6"
                  sizeSm="6"
                  sizeMd="2"
                  sizeLg="2"
                  key={g.gameName}
                  className="dc-ion-col"
                >
                  <div className="container1"
                    onClick={() =>
                      handleGameClick(
                        g.gameId,
                        g.gameName,
                        g.gameCode,
                        g.subProviderName,
                        g.providerName
                      )
                    }>
                    <img
                      src={LIVE_WEB_CASINO1[g.gameId] ? LIVE_WEB_CASINO1[g.gameId] : g.urlThumb}
                      alt={g.gameName}
                      className="dc-img"
                    />
                    <GetMinAndMaxValues game={g}/>
                    <div className="game-name">
                      <span className="name web-view">
                        {g?.gameName.length > 19 ? g?.gameName?.slice(0, 20) + ".." : g?.gameName}
                      </span>
                      <span className="name mob-view">
                        {g?.gameName.length > 15 ? g?.gameName?.slice(0, 15) + ".." : g?.gameName}
                      </span>
                      <span className="p-name">{g.subProviderName}</span>
                      {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}

                    </div>
                    {/* <div className="overlay1">
                      <div className="text1">
                        <div
                          className="custom-btn"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img src={inplay} alt="inplay" />
                          <div> PLAY NOW </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </>
        ) : null}
      </div>
      <Copyright />
      {/* <Footer /> */}

      <Backdrop className="backdrop-ctn" open={showDialog}>
        <LoginModal
          open={showDialog}
          closeHandler={() => setDialogShow(false)}
          noTitle={true}
          size="sm"
          customClass={'login-modal-ctn'}
        >
          <LoginForm modalCloseHandler={() => setDialogShow(false)}></LoginForm>
        </LoginModal>
      </Backdrop>

      {/* <Dialog
        open={infoDialog}
        onClose={() => setInfoDialog(false)}
        aria-labelledby="form-dialog-title"
        className="dialog-div"
      >
        <div className="dialog-div-body">
          <div className="go-corner" onClick={() => setInfoDialog(false)}>
            <div className="go-arrow">
              <ClearIcon />
            </div>
          </div>
          <div className="dialog-title">
            <StarBorderRoundedIcon />
            Welcome
            <StarBorderRoundedIcon />
          </div>

          {getCurrencyTypeFromToken() === 0 ? (
            <div className="dialog-body">
              1 Live casino point is equal to 10 normal points
              <div className="dialog-ex">
                {' '}
                Ex: 100 casino points = 1000 normal points
              </div>
              <p className="dialog-ex">
                Maximum Bet limit is 50000 casino points
              </p>
            </div>
          ) : null}

          {getCurrencyTypeFromToken() === 1 ? (
            <>
              {' '}
              <div className="dialog-body">
                <div className="dialog-ex">
                  {' '}
                  Maximum Bet Limit For All Games is 5000
                </div>
              </div>
            </>
          ) : null}

          <div
            className="dialog-continue"
            onClick={() => {
              getGameUrl(
                selectedEvolutionGame?.gameId,
                selectedEvolutionGame?.gameName,
                selectedEvolutionGame?.gameCode,
                selectedEvolutionGame?.provider,
                selectedEvolutionGame?.subProvider
              );
            }}
          >
            <Button className="btn">Continue</Button>
          </div>
        </div>
      </Dialog> */}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
    liveCasinoGames: state.indianCasino.liveCasinoGames,
    scroll: state.common.scroll,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    FatchLiveCasinoGames: (type: string) =>
      dispatch(FatchLiveCasinoGames(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualSports);
