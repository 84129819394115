import { IonSpinner } from '@ionic/react';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { forwardRef, useEffect, useState } from 'react';
import './ZenpayCrypto.scss';
import '../Deposit.scss'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import AGPAY_API from '../../../api-services/agpay-api';


interface ZenPayTabPanelProps {
  setLoading: Function;
  loading: boolean;
  copyText: (text: string, toastMsg: string) => void;
}

const ZenPayCrypto = forwardRef<HTMLInputElement, ZenPayTabPanelProps>(
  (
    {
      setLoading,
      loading,
      copyText,
    }
  ) => {
    const [currencyList, setCurrencyList] = useState<any>([]);
    const [selectedCrypto, setSelectedCrypto] = useState<any>({});
    const [amountToBePaid, setAmountToBePaid] = useState<string>('');
    const [transactionId, setTransactionId] = useState<string>('');
    const [transferReferenceId, setTransferReferenceId] = useState<string>('');
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [txnHash, setTxnHash] = useState<string>('');

    const [depositAmount, setDepositAmount] = useState('');

    const getCurrencyList = async () => {
      try {
        const response = await AGPAY_API.get(
          `/agpay/v2/zenpay-crypto/currencies`,
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          }
        );
        if (response.status === 200) {
          setCurrencyList(response?.data?.crypto_currency);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const submitCryptoAmount = async (e) => {
      e.preventDefault();
      if (Object.keys(selectedCrypto).length == 0) {
        toast.error("Select crypto currency")
        return false;
      }
      setLoading(true);
      try {
        const payload = {
          amount: depositAmount,
          crypto_currency: selectedCrypto.crypto_currency,
          network_id: selectedCrypto.network_id,
          currency_type: 'INR',
          mobile_number: "9876543211",
        };
        const response = await AGPAY_API.post(
          `/agpay/v2/zenpay-crypto/transactions/:deposit`,
          payload,
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          }
        );
        if (response.status === 200) {
          setAmountToBePaid(response.data?.crypto_amount);
          setTransactionId(response.data?.transaction_id);
          setTransferReferenceId(response.data?.transfer_reference_id);
          setWalletAddress(response?.data?.wallet_address);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        toast.error(err?.response?.data?.message)
      }
    };

    const submitCryptoPayment = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const payload = {
          transfer_reference_id: transferReferenceId,
          transaction_hash: txnHash,
          transaction_id: transactionId,
        };

        const response = await AGPAY_API.post(
          `/agpay/v2/zenpay-crypto/confirm-payment`,
          payload,
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          }
        );
        if (response.status == 200) {
          toast.success("Transaction Saved Successfully!");
          window.location.href = '/my_transactions';
        }
        setLoading(false);
      } catch (error) {
        toast.error(error?.response?.data?.message)

        setLoading(false);
      }
    };

    useEffect(() => {
      getCurrencyList();
    }, [])

    return (
      <>
        <form
          className="account-inputs"
          onSubmit={(e) => submitCryptoAmount(e)}
        >
          <div className="select-template">
            <div className="st-label">{'Select Currency'}</div>
            <Select
              value={selectedCrypto}
              disabled={amountToBePaid ? true : false}
              onChange={(e: any) => setSelectedCrypto(e.target.value)}
              className="select-compo"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {currencyList.map((indv) => (
                <MenuItem key={indv.crypto_currency} value={indv}>
                  {indv.crypto_currency + ' (' + indv.blockchain + ')'}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className='account-input'>
            <div className="account-label">Enter Amount</div>
            <NumberFormat
              required
              className={
                transferReferenceId
                  ? 'disabled acc-input'
                  : 'acc-input'
              }
              placeholder="Enter Amount (INR)"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
              decimalSeparator={false}
              disabled={
                transferReferenceId
                  ? true
                  : false
              }
            />
          </div>
          {!amountToBePaid && (
            <Button
              className={
                loading ||
                  !depositAmount ||
                  !selectedCrypto
                  ? "submit-payment-btn submit-btn-disabled"
                  : "submit-payment-btn"
              }
              type="submit"
              disabled={
                loading ||
                  !depositAmount ||
                  !selectedCrypto
                  ? true
                  : false
              }
              endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
            >
              Next
            </Button>
          )}
        </form>
        {transferReferenceId &&
          <form
            className='account-inputs'
            onSubmit={submitCryptoPayment}
          >
            <div className="account-inputs">
              <div className="account-input">
                <div className="payment-detail">
                  Amount:
                  <span
                    title='Click to copy'
                    className='payment-detail-input'
                    onClick={() =>
                      copyText(amountToBePaid, "Amount Copied")
                    }
                  >
                    {amountToBePaid + " " + selectedCrypto.crypto_currency}
                  </span>
                </div>
              </div>
            </div>
            <div className="account-inputs">
              <div className="account-input">
                <div className="payment-detail">
                  Wallet Address:
                  <span
                    title='Click to copy'
                    className='payment-detail-input'
                    onClick={() =>
                      copyText(walletAddress, "Wallet Address copied")
                    }
                  >
                    {walletAddress}
                  </span>
                </div>
              </div>
            </div>
            <div className='account-input'>
              <div className="account-label">Enter Transaction hash</div>
              <TextField
                required
                className={
                  'acc-input'
                }
                placeholder="Enter Transaction Hash"
                value={txnHash}
                onChange={(e) => setTxnHash(e.target.value)}
              />
              <Button
                className="submit-payment-btn"
                type="submit"
                disabled={loading ? true : false}
                endIcon={
                  loading ? <IonSpinner name="lines-small" /> : ''
                }
              >
                Confirm Payment
              </Button>
            </div>
          </form>
        }
      </>
    );
  }
);

export default ZenPayCrypto;
