import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as VCasino } from '../../assets/images/footer/virtual-casino.svg';
import { ReactComponent as Home } from '../../assets/images/home/homeview/home.svg';
import { ReactComponent as Live } from '../../assets/images/home/homeview/live.svg';
import { RootState } from '../../models/RootState';
import './Footer.scss';

import { setScroll } from '../../store/common/commonActions';

type StoreProps = {
  allowedConfig: number;
  scroll: boolean;
  prefersDark: string;
  setScrollDispatch: Function;
};

const Footer: React.FC<StoreProps> = (props) => {
  const { scroll, setScrollDispatch } = props;
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const footerTabs = [
    {
      img: Live,
      tabName: 'LIVE',
      path: '/live-casino'
    },
    {
      img: Home,
      tabName: 'HOME',
      path: '/home'
    },
    {
      img: VCasino,
      tabName: 'VIRTUAL',
      path: '/virtual/casino'
    },
  ];

  const handleClick = (idx, path) => {
    setSelectedTab(idx);
    history.push(path);
  }

  useEffect(() => {
    footerTabs.forEach((tab: any, i:number) => {
      if (window.location.pathname.includes(tab.path)) {
        setSelectedTab(i);
      }
    })
  }, [window.location.pathname])

  return (
    <>
      <div className="mob-footer mob-view">
        {footerTabs.map((tab: any, i: number) => (
          <button className={`footer-tab ${selectedTab === i ? 'active-tab' : ''}`} onClick={() => {
              setScrollDispatch(!scroll);
              handleClick(i, tab.path);
            }}>
            <tab.img className="footer-icon" />
            <div className="footer-tab-name">{tab.tabName}</div>
          </button>
        ))}
        {/* <NavLink to={'/live-casino'} className="footer-btn live">
          <LinkFooter className="svg-top-border" />
          <div className="svg-container">
            <Live /><span>LIVE</span>
          </div>
          <div className="svg-text">Live Casino</div>
          <Live className="svgs home-footer-icon" />
          <span>LIVE</span>
          <div className="svgs">LIVE</div>
        </NavLink> */}

        {/* <NavLink to={'/home'} className="footer-btn live">
          <LinkFooter className="svg-top-border" />
          <div className="svg-container">
            <div className="svg">
              <Home />
            </div>
          </div>
          <div className="svg-text">HOME</div>
          <Home className="svgs home-footer-icon" />
          <div>HOME</div>
        </NavLink> */}

        {/* {
          EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? (
            <>
              <NavLink className="footer-btn live" to={'/premium_sports'}>
                <LinkFooter className="svg-top-border" />
                <div className="svg-container">
                  <div className="svg">Sport</div>
                </div>
                <div className="svg-text">Sportsbook</div>

                <div className="svgs">SPORT</div>
              </NavLink>
              <NavLink className="footer-btn live" to={'/exchange'}>
                <LinkFooter className="svg-top-border" />
                <div className="svg-container">
                  <div className="svg">EXCH</div>
                </div>
                <div className="svg-text">Exchange</div>

                <div className="svgs">EXCH</div>
              </NavLink>
            </>
          ) : null
        } */}

        {/* {!EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? (
          <NavLink to={'/virtual/casino'} className="footer-btn virtual-casino">
            <LinkFooter className="svg-top-border" />
            <div className="svg-container">
              <div className="svg">
                <VCasino />
              </div>
            </div>
            <div className="svg-text">Virtual Casino</div>

            <VCasino className="svgs" />
            <div>VIRTUAL</div>
          </NavLink>
        ) : null} */}
        {/* <NavLink to={'/slot_games'} className="footer-btn slots">
          <LinkFooter className='svg-top-border' />
          <div className="svg-container">
            <div className="svg">
              <Slots />
            </div>
          </div>
          <div className="svg-text">Slot Games</div>

          <Slots className='svgs' />
        </NavLink>
        <NavLink to={'/cards'} className="footer-btn fun-games">
          <LinkFooter className='svg-top-border' />
          <div className="svg-container">
            <div className="svg">
              <FunGames />
            </div>
          </div>
          <div className="svg-text">Fun Games</div>

          <FunGames className='svgs' />
        </NavLink>
        <NavLink to={'/providers'} className="footer-btn fish-shooting">
          <LinkFooter className='svg-top-border' />
          <div className="svg-container">
            <div className="svg">
              <FishShooting />
            </div>
          </div>
          <div className="svg-text">Fish Shooting</div>

          <FishShooting className='svgs' />
        </NavLink> */}
        {/* <NavLink to={'/virtual/sports'} className="footer-btn virtuals-sports">
          <LinkFooter className='svg-top-border' />
          <div className="svg-container">
            <div className="svg">
              <VSports />
            </div>
          </div>
          <div className="svg-text">Virtual Casino</div>

          <VSports className='svgs' />
        </NavLink> */}
      </div>
      {/* <Copyright /> */}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allowedConfig: state.common.allowedConfig,
    scroll: state.common.scroll,
    prefersDark: state.common.prefersDark,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setScrollDispatch: (data: boolean) => dispatch(setScroll(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
