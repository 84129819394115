import {
  IonRow,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonBadge,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import { connect } from 'react-redux';

import { getOutcomeDescName, SportIdByNameMap } from '../../util/stringUtil';
import { UserBet } from '../../models/UserBet';
import Spinner from '../Spinner/Spinner';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import {
  getOutcomeDescByEnumName,
  MarketTypeByEnumMap,
} from '../../util/stringUtil';
import REPORTING_API from '../../reporting-api';
import Copyright from '../Copyright/Copyright';
import { RootState } from '../../models/RootState';
import { getCurrencyTypeFromToken } from '../../store';
import './MyBetsView.scss';

type StoreProps = {
  allowedConfig: number;
};

const MyBets: React.FC<StoreProps> = (props) => {
  const [bets, setBets] = useState<UserBet[]>([]);
  const [sportFilter, setSportFilter] = useState<string>('All');
  const [filter, setFilter] = useState<string>('Open');
  const [games, setGames] = useState<string>('CASINO');
  const [currpage, setcurrpage] = useState<number>(1);
  const [dataflag, setdataflag] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortDesc, setsortDesc] = useState<boolean>(true);
  const [dateFrom, setDateFrom] = useState<Moment>(moment().subtract(7, 'd'));
  const [dateTo, setDateTo] = useState<Moment>(moment());
  const [categoryType, setCategoryType] = useState<string>('ALL');
  const pageSize = 25;
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const [pageToken, setPageToken] = useState<string[]>(['']);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [paginationFlag, setPaginationFlag] = useState<boolean>(false);

  const gameHandler = (game: string) => {
    setGames(game);
    setLoading(true);
    setcurrpage(1);
    setPageToken(['']);
  };

  const filterHandler = (filter: string) => {
    setFilter(filter);
    setLoading(true);
    setcurrpage(1);
    setPageToken(['']);
  };

  const categoryHandler = (category: string) => {
    setCategoryType(category);
    setLoading(true);
    setcurrpage(1);
    setPageToken(['']);
  }

  const nextPage = () => {
    setLoading(true);
    setcurrpage(currpage + 1);
    if (nextPageToken !== null && nextPageToken !== '') {
      setPageToken([...pageToken, nextPageToken]);
    }
  };

  const prevPage = () => {
    setLoading(true);
    setcurrpage(currpage - 1);
    setPaginationFlag(true);
    if (pageToken.length > 1) {
      let pagetokens = pageToken;
      pagetokens.pop();
      setPageToken([...pagetokens]);
    }
    setLoading(false);
    setPaginationFlag(false);
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await REPORTING_API.get('reports/v2/orders/:search', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
        Accept: "application/json",
      },
      params: {
        reportType: 'ORDER_LIST',
        categoryType:
          categoryType.toLowerCase() === 'all' ? null : categoryType,
        status:
          filter === 'unmatched'
            ? 'Unmatched'
            : filter === 'All'
            ? null
            : filter,
        sportId:
          games === 'SPORTS'
            ? sportFilter == 'All'
              ? null
              : sportFilter
            : null,
        sortOrder: sortDesc ? 'DESC' : 'ASC',
        pageSize: pageSize,
        pageToken: pageToken[pageToken.length - 1],
        startDate: dateFrom.startOf('day').toISOString(),
        endDate: dateTo.endOf('day').toISOString(),
      },
    });
    let betList = response.data?.orders;
    for (const bet of betList) {
      bet.stakeAmount = bet.stakeAmount / cFactor;
      bet.payOutAmount = bet.payOutAmount / cFactor;
    }
    setNextPageToken(response.data?.pageToken);
    setBets([]);
    setBets(betList);
    setdataflag(1);
    setLoading(false);
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchData();
  }, [
    filter,
    sportFilter,
    games,
    sortDesc,
    dateFrom,
    dateTo,
    cFactor,
    pageToken,
    categoryType
  ]);

  const onSportFilterChange = (sport: string) => {
    setSportFilter(sport);
    setPageToken(['']);
  };

  return (
    <>
      <div className="reports-ctn my-bets-ctn">
        <div className="header-ctn">
          <IonRow className="header-ctn-row">
            <div className="img-page-title-row">
              <div className="title">Bet List</div>
            </div>
            <div className="title-border-line"></div>

            <div className="filters-row">
              <div className="select-filter web-view">
                <div className="select-filter-label">Bets Status</div>
                <IonSelect
                  className="select-filter-control"
                  value={filter}
                  placeholder="Select One"
                  interface="popover"
                  onIonChange={(e) => filterHandler(e.detail.value)}
                >
                  <IonSelectOption value="All">All</IonSelectOption>
                  <IonSelectOption value="Open">Open</IonSelectOption>
                  <IonSelectOption value="Settled">Settled</IonSelectOption>
                  <IonSelectOption value="Won">Won</IonSelectOption>
                  <IonSelectOption value="Lost">Lost</IonSelectOption>
                  <IonSelectOption value="Voided">Void</IonSelectOption>
                </IonSelect>
              </div>
              <div className="select-filter web-view">
                <div className="select-filter-label">Category Type</div>
                <IonSelect
                  className="select-filter-control"
                  value={categoryType}
                  placeholder="Select One"
                  interface="popover"
                  onIonChange={(e) => categoryHandler(e.detail.value)}
                >
                  <IonSelectOption value="All">All</IonSelectOption>
                  <IonSelectOption value="SPORTS">sports</IonSelectOption>
                  <IonSelectOption value="SPORTS_BOOK">sports book</IonSelectOption>
                  <IonSelectOption value="CASINO">casino</IonSelectOption>
                  <IonSelectOption value="SAP_EXCHANGE">exchange</IonSelectOption>
                </IonSelect>
              </div>

              <div className="date-section">
                <div className="date-filter mr-11">
                  <div className="date-filter-label">From</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      disableToolbar
                      variant="inline"
                      minDate={moment().subtract(1, 'months').calendar()}
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="from-date"
                      value={dateFrom}
                      onChange={(e) => {
                        if (e == null) {
                          setLoading(true);
                          setDateFrom(null);
                          setPageToken(['']);
                        } else {
                          setLoading(true);
                          setDateFrom(e);
                          setPageToken(['']);
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="date-filter">
                  <div className="date-filter-label">To</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      disableToolbar
                      variant="inline"
                      minDate={moment().subtract(1, 'months').calendar()}
                      maxDate={moment()}
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="to-date"
                      value={dateTo}
                      onChange={(e) => {
                        if (e == null) {
                          setLoading(true);
                          setDateTo(null);
                          setPageToken(['']);
                        } else {
                          setLoading(true);
                          setDateTo(e);
                          setPageToken(['']);
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="select-filter mob-view">
                <div className="select-filter-label">Bets Status</div>
                <IonSelect
                  className="select-filter-control"
                  value={filter}
                  placeholder="Select One"
                  interface="popover"
                  onIonChange={(e) => filterHandler(e.detail.value)}
                >
                  <IonSelectOption value="All">All</IonSelectOption>
                  <IonSelectOption value="Open">Open</IonSelectOption>
                  <IonSelectOption value="Settled">Settled</IonSelectOption>
                  <IonSelectOption value="Won">Won</IonSelectOption>
                  <IonSelectOption value="Lost">Lost</IonSelectOption>
                  <IonSelectOption value="Voided">Void</IonSelectOption>
                </IonSelect>
              </div>
              <div className="select-filter mob-view">
                <div className="select-filter-label">Category Type</div>
                <IonSelect
                  className="select-filter-control"
                  value={categoryType}
                  placeholder="Select One"
                  interface="popover"
                  onIonChange={(e) => categoryHandler(e.detail.value)}
                >
                  <IonSelectOption value="All">All</IonSelectOption>
                  <IonSelectOption value="SPORTS">sports</IonSelectOption>
                  <IonSelectOption value="SPORTS_BOOK">sports book</IonSelectOption>
                  <IonSelectOption value="CASINO">casino</IonSelectOption>
                  <IonSelectOption value="SAP_EXCHANGE">exchange</IonSelectOption>
                </IonSelect>
              </div>
            </div>
          </IonRow>
        </div>

        <div className="content-ctn light-bg my-bets-content">
          <div className="myb-bets-div">
            {loading ? (
              <Spinner />
            ) : (
              <>
                {bets.length > 0 || dataflag === 0 ? (
                  <div className="tbl-ctn">
                    <TableContainer component={Paper}>
                      <Table className="myb-table" size="small">
                        <TableHead className="myb-table-header">
                          <TableRow>
                            <TableCell className="web-view" align="center">
                              Place Date
                            </TableCell>
                            <TableCell align="center" className="th-match">
                              Sport
                            </TableCell>
                            <TableCell align="center" className="th-match">
                              Match
                            </TableCell>
                            {/* <TableCell className="web-view" align="center">
                              Match Date
                            </TableCell> */}
                            <TableCell align="center">Market</TableCell>
                            {/* <TableCell align="center" className="web-view">
                              Bet On
                            </TableCell> */}
                            <TableCell align="center">Odds</TableCell>
                            <TableCell align="center">Stake</TableCell>
                            <TableCell align="center">Outcome</TableCell>
                            <TableCell align="center">Returns</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="myb-table-body">
                          {bets.map((row) => (
                            <Row key={row.id} row={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : (
                  <div className="no-data-row">No bets found</div>
                )}
              </>
            )}
            <IonRow className="mt-10">
              {currpage === 1 || loading ? null : (
                <IonButton
                  className="myb-btn-prev"
                  onClick={(e) => prevPage()}
                  disabled={pageToken.length < 2 || paginationFlag}
                >
                  Prev({pageToken.length - 1})
                </IonButton>
              )}

              {bets.length === pageSize && !loading ? (
                <IonButton
                  className="myb-btn-next"
                  onClick={(e) => nextPage()}
                  disabled={
                    nextPageToken === '' ||
                    nextPageToken === null ||
                    paginationFlag
                  }
                >
                  Next({pageToken.length + 1})
                </IonButton>
              ) : null}
            </IonRow>
          </div>
        </div>
      </div>
      <Copyright />
    </>
  );
};

function Row(props: { row: UserBet }) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow
        className={
          (row.oddValue && row.betType === 0) || row.eventName === 'CASINO'
            ? 'myb-table-row back-odd-row'
            : row.oddValue && row.betType === 1
            ? 'myb-table-row lay-odd-row'
            : 'myb-table-row'
        }
      >
        <TableCell
          className="web-view"
          component="th"
          scope="row"
          align="center"
        >
          {moment(row.betPlacedTime).format('DD-MM-YY, h:mm:ss A')}
        </TableCell>

        <TableCell align="center" className="th-match">
          <span className="t-uppercase">{row?.marketType}</span>
        </TableCell>

        <TableCell className="myb-table-cell td-match" align="center">
          <div className="web-view">
            <span className="event-link">{row.categoryType==="CASINO"? (row.competitionName)+'/':null}{row.eventName}</span>
          </div>

          <div className="mob-view">
            <div className="game-label col-data-header mob-fs-13">
            {row.categoryType==="CASINO"? (row.competitionName)+'/':null}{row.eventName}{' '}
              {getOutcomeDescName(row.outcomeResult.toString())
                ? `- ${getOutcomeDescName(row.outcomeResult.toString())}`
                : ''}{' '}
              {row.outcomeResult == 4 ? `(${row.outcomeDesc})` : ''}
            </div>
            <div className="market-label col-data-header">
              {' '}
              {row.marketType === 'FANCY'
                ? row.marketName +
                  ' @ ' +
                  Number(row.oddValue * 100 - 100).toFixed(0)
                : row.marketType === 'BINARY'
                ? row.outcomeDesc + ' @ ' + Number(row.sessionRuns).toFixed(0)
                : row.outcomeDesc}
            </div>
            <div className="col-data-desc">
              {moment(row.betPlacedTime).format('DD-MM, h:mm:ss A')}
            </div>
          </div>
        </TableCell>

        <TableCell className="myb-table-cell td-market" align="left">
          {/* {row.marketType === 'BOOKMAKER'
            ? row.marketName || MarketTypeByEnumMap[row.marketType]
            : MarketTypeByEnumMap[row.marketType]
            ? MarketTypeByEnumMap[row.marketType]
            : 'Casino'} */}
          {/* <span className="event-name">({SportIdByNameMap[row?.sportId]})</span> */}
          Casino
        </TableCell>

        {/* <TableCell className="myb-table-cell td-bet-on" align="left">
          {row.outcomeDesc}
        </TableCell> */}
        <TableCell className="myb-table-cell td-odd" align="center">
          <span className="mob-fs-13">
            {row.oddValue && row.oddValue !== -1.0
              ? row.oddValue.toFixed(2)
              : '-'}
          </span>
        </TableCell>
        <TableCell className="myb-table-cell td-stake" align="center">
          <span className="mob-fs-14">{row.stakeAmount.toString()}</span>
        </TableCell>
        <TableCell className="myb-table-cell td-outcome" align="left">
          {getOutcomeDescByEnumName(row.outcomeResult.toString())
            ? getOutcomeDescByEnumName(row.outcomeResult.toString())
            : 'Unsettled'}
        </TableCell>
        {/* <TableCell className="myb-table-cell" align="center">
          {row?.betFairStatus ? row?.betFairStatus : '-'}
        </TableCell> */}
        <TableCell
          className={
            row.payOutAmount > 0
              ? 'myb-table-cell profit-bet'
              : 'myb-table-cell loss-bet'
          }
          align="center"
        >
          <span className="mob-fs-14">
            {row.outcomeResult === 'Open' ? '-' : row.payOutAmount.toFixed(2)}
          </span>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    allowedConfig: state.common.allowedConfig,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBets);
