import {
  IonCol,
  IonIcon,
  IonRow,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import { Backdrop, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams ,useLocation} from 'react-router-dom';
import LoginForm from '../../../components/LoginForm/LoginForm';
import LoginModal from '../../../components/Modal/LoginModal';
import { RootState } from '../../../models/RootState';
import { GapGame } from '../../../models/dc/DcGame';
import { FatchLiveCasinoGames } from '../../../store';
import './CasinoNew.scss';

import AllIcon from '../../../assets/images/casino/icons/all.svg';
import IndianCasinoIcon from '../../../assets/images/casino/icons/indian.svg';
import BlackJackIcon from '../../../assets/images/casino/icons/blackjack.svg';
import BackarratIcon from '../../../assets/images/casino/icons/backarrat.svg';
import CrashIcon from '../../../assets/images/casino/icons/crash.svg';
import RouletteIcon from '../../../assets/images/casino/icons/roulette.svg';
import TeenpattiIcon from '../../../assets/images/casino/icons/teenpatti.svg';
import Copyright from '../../../components/Copyright/Copyright';
import TabPanel from '../../../components/TabPanel/TabPanel';
import { BRAND_NAME } from '../../../constants/Branding';
import {
  EzugiChanges
} from '../../../constants/WhitelabelConfig';
import GetMinAndMaxValues from './GetMinAndMaxValues';

type StoreProps = {
  loggedIn: boolean;
  loggedInUserStatus: any;
  FatchLiveCasinoGames: Function;
  liveCasinoGames: GapGame[];
  scroll: boolean;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LiveCasino: React.FC<StoreProps> = (props) => {
  const {
    loggedIn,
    loggedInUserStatus,
    liveCasinoGames,
    FatchLiveCasinoGames,
    scroll
  } = props;

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [casinoGameCatTabValue, setCasinoGameCatTabValue] = useState<number>(0);
  const [providerMap , setProviderMap]=useState<Map<string,string>>();
  const [filteredProvider, setFilteredProvider] = useState<string>(useQuery().get('provider'));
  const [providerList, setProviderList] = useState<string[]>();
  let filterList = [
    { label: 'Live Casino', value: 0 },
    { label: 'Roulette', value: 1 },
    { label: 'Baccarat', value: 2 },
    { label: 'BlackJack', value: 3 },
    { label: 'Poker', value: 4 },
    { label: 'aviator', value: 7 },
  ];
  const pathParams = useParams();
  const Selectedprovider = pathParams['provider'];
  const gameName = pathParams['gameName'];
  const tabArray = [
    'all',
    'roulette',
    'baccarat',
    'blackJack',
    'poker',
    'evolution',
    'Pragmatic Play Live',
    'aviator',
    'Others',
  ];
  const divRef: any = useRef();
  const scrollToElement = () => {
    const current: any = divRef;
    if (current !== null){
        current?.current?.scrollIntoView({behavior: "smooth"})
    }
  }

useEffect(() => {
  scrollToElement();
}, [scroll])

  useEffect(() => {
    let index = tabArray.indexOf(gameName);
    if (index > -1) {
      setCasinoGameCatTabValue(index);
    } else {
      setCasinoGameCatTabValue(0);
    }
  }, [gameName]);

  const history = useHistory();

  const liveCasinoGamesMap = {
    blackJack: 'Live Blackjack',
    baccarat: 'Live Baccarat',
    roulette: 'Live Roulette',
    andarBahar: 'Andar Bahar',
    poker: 'Live Poker',
    other: 'Others',
  };

  const setDialogShow = (show: boolean) => {
    setModalOpen(true);
  };

  useEffect(() => {
    console.log(providerMap)
    FatchLiveCasinoGames('casino',providerMap?providerMap[filteredProvider]:'*',filteredProvider);
  }, []);

  useEffect(() => {
    if (liveCasinoGames?.length > 0 && filteredProvider==null){
      let prvMap = new Map<string ,string>()
    for(let lcg of liveCasinoGames) {
prvMap[lcg.subProviderName]=lcg.providerName
    }
    setProviderMap(prvMap)
      setProviderList(
        liveCasinoGames
          .map((g) => g.subProviderName)
          .filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
      );}
  }, [liveCasinoGames]);

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedIn) {
      if (loggedInUserStatus === 0 || loggedInUserStatus === 3) {
        history.push(`/home`);
      }
      if (provider === 'Indian Casino') {
        history.push(`/casino/indian/${gameCode}`);
      } else {
        history.push(
          `/gap_casino/game/${gameName
            .toLowerCase()
            .replace(/\s+/g, '-')}-${btoa(gameId.toString())}-${btoa(
            gameCode
          )}-${btoa(provider)}-${btoa(subProvider)}`
        );
      }
    } else {
      setDialogShow(true);
    }
  };

  const handleGameClick = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider?: string
  ) => {
    getGameUrl(gameId, gameName, gameCode, provider, subProvider);
  };

  let baccaratList = liveCasinoGames.filter(
    (g) =>
      g.category.toLowerCase().includes('baccarat') ||
      g.gameName.toLowerCase().includes('baccarat')
  );

  let rouletteList = liveCasinoGames.filter(
    (g) =>
      g.category.toLowerCase().includes('roulette') ||
      g.gameName.toLowerCase().includes('roulette')
  );

  let pokerList = liveCasinoGames.filter(
    (g) =>
      g.category.toLowerCase().includes('poker') ||
      g.gameName.toLowerCase().includes('poker')
  );

  let otherList = liveCasinoGames.filter(
    (g) =>
      g.category === liveCasinoGamesMap.other ||
      ['Onetouch Live', 'Asia Gaming'].includes(g.subProviderName)
  );

  let blackjackList = liveCasinoGames.filter(
    (g) => g.category === liveCasinoGamesMap.blackJack
  );

  let evolutionList = liveCasinoGames.filter(
    (g) => g.subProviderName === 'Evolution Gaming'
  );
  let superspadeList = liveCasinoGames.filter(
    (g) => g.subProviderName === 'Pragmatic Play Live'
  );
  let pragmaticList = liveCasinoGames.filter(
    (g) => g.subProviderName === 'Pragmatic Play Live'
  );
  let betGamesList = liveCasinoGames.filter(
    (g) => g.subProviderName === 'BetGames.TV'
  );
  let virtualList = liveCasinoGames.filter(
    (g) => g.category === 'Virtual Sports'
  );

  const aeSexy = liveCasinoGames.filter(
    (g) => g.subProviderName === 'AE SEXYBCRT'
  );
  const superNowaGame = liveCasinoGames.filter(
    (g) => g.subProviderName.toLowerCase() === 'supernowa'
  );

  let crashGamesList = liveCasinoGames.filter(
    (g) => g.category === 'Crash Games'
  );

  let teenpattiGamesList = liveCasinoGames.filter(
    (g) => g.category === 'Teenpatti' && (g.gameName.includes('Teenpatti') || g.gameName.includes('Teen Patti'))
  );

  let andarBahaarList = liveCasinoGames.filter(
    (g) => g.category === liveCasinoGamesMap.andarBahar
  );

  return (
    <div ref={divRef} className="dc-page-bg">
      <div className="dc-ctn">
        <IonRow class="casino-filters-row">
          <div className="title">Live Casino</div>
        </IonRow>
        <div className="title-border-line"></div>

        <div className="search-options-ctn">
          <Tabs
            value={casinoGameCatTabValue}
            onChange={(_, newValue) => {
              setCasinoGameCatTabValue(newValue);
            }}
            variant="scrollable"
            scrollButtons="auto"
            className="game-types-tabs"
          >
            <Tab
              className="tab-casino-btn"
              // label="ALL"
              value={0}
              icon={<IonIcon className="casino-icon home-icon" src={AllIcon} />}
            />
            <Tab
              className="tab-casino-btn"
              // label="Indian"
              value={1}
              icon={<IonIcon className="casino-icon" src={IndianCasinoIcon} />}
            />
            <Tab
              className="tab-casino-btn"
              // label="TEEN PATTI"
              value={7}
              icon={<IonIcon className="casino-icon" src={TeenpattiIcon} />}
            />
            <Tab
              className="tab-casino-btn"
              // label="ROULETTE"
              value={2}
              icon={<IonIcon className="casino-icon" src={RouletteIcon} />}
            />
            <Tab
              className="tab-casino-btn"
              // label="BACCARAT"
              value={5}
              icon={<IonIcon className="casino-icon" src={BackarratIcon} />}
            />
            <Tab
              className="tab-casino-btn"
              // label="BlackJack"
              value={3}
              icon={<IonIcon className="casino-icon" src={BlackJackIcon} />}
            />
            <Tab
              className="tab-casino-btn"
              // label="CRASH"
              value={6}
              icon={<IonIcon className="casino-icon" src={CrashIcon} />}
            />
            {/* <Tab
              className="tab-casino-btn"
              label="Poker"
              value={4}
              icon={<IonIcon className="casino-icon" src={PokerIcon} />}
            /> */}
            {/* <Tab
              className="tab-casino-btn"
              label="Other"
              value={8}
              icon={<IonIcon className="casino-icon" src={OtherIcon} />}
            /> */}
          </Tabs>

          <div style={{ display: 'flex' }} className="mob-mt-10">
          <IonSelect
              className="casino-search"
              value={filteredProvider}
              placeholder="Select One"
              interface="popover"
              onIonChange={(e) => setFilteredProvider(e.detail.value)}
            >
              <IonSelectOption key={'option--1'} value={''}>
                {'All'}
              </IonSelectOption>
              {providerList &&
                providerList.map((opt, idx) => (
                  <IonSelectOption key={'option-' + idx} value={opt}>
                    {opt}
                  </IonSelectOption>
                ))}
            </IonSelect>
            {/* <IonSelect
              className="casino-search mr-10"
              value={casinoGameCatTabValue}
              placeholder="Live Casino"
              interface="popover"
              onIonChange={(e) => setCasinoGameCatTabValue(e.detail.value)}
            >
              {filterList &&
                filterList.map((opt, idx) => (
                  <IonSelectOption key={'option-' + idx} value={opt.value}>
                    {opt.label}
                  </IonSelectOption>
                ))}
            </IonSelect> */}

            {/* <IonSelect
              className="casino-search"
              value={filteredProvider}
              placeholder="Select One"
              interface="popover"
              // disabled={
              //   EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? true : false
              // }
              onIonChange={(e) => {
                setFilteredProvider(e.detail.value);
              }}
            >
               <IonSelectOption
                key={'option--1'}
                value={
                  EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? 'Ezugi' : ''
                }
              >
                {EzugiChanges[BRAND_NAME.toLocaleLowerCase()]
                  ? 'Spribe'
                  : 'Select Provider'}
              </IonSelectOption>
             
              {EzugiChanges[BRAND_NAME.toLocaleLowerCase()]
                ? null
                : providerList &&
                  providerList.map((opt, idx) =>
                    opt && opt === 'Ezugi' ? null : (
                      <IonSelectOption key={'option-' + idx} value={opt}>
                        {opt}
                      </IonSelectOption>
                    )
                  )}
            </IonSelect> */}
          </div>
        </div>

        {/* {virtualList && virtualList.length > 0 ? ( */}
        <TabPanel value={casinoGameCatTabValue} index={0}>
          {liveCasinoGames && liveCasinoGames.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {/* {allList.map((g, i) => ( */}
                {liveCasinoGames.map((g, i) =>
                  <>
                  {filteredProvider &&
                  g.subProviderName?.toLowerCase() !==
                    filteredProvider.toLowerCase() ? null : (
                    <IonCol
                      sizeXs="6"
                      sizeSm="6"
                      sizeMd="2"
                      sizeLg="2"
                      key={g.gameName}
                      className="dc-ion-col"
                    >
                      <div
                        className="container1"
                        onClick={() =>
                          handleGameClick(
                            g.gameId,
                            g.gameName,
                            g.gameCode,
                            g.subProviderName,
                            g.providerName
                          )
                        }
                      >
                        <img
                          src={
                            g.urlThumb
                          }
                          alt={g.gameName}
                          className="dc-img"
                        />
                        <GetMinAndMaxValues game={g}/>
                        <div className="game-name">
                          <span className="name web-view">
                            {g?.gameName.length > 19
                              ? g?.gameName?.slice(0, 20) + '..'
                              : g?.gameName}
                          </span>
                          <span className="name mob-view">
                            {g?.gameName.length > 15
                              ? g?.gameName?.slice(0, 15) + '..'
                              : g?.gameName}
                          </span>
                          <span className="p-name">{g.subProviderName}</span>
                          {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                        </div>
                      </div>
                    </IonCol>
                  )}
                  </>
                )}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={1}>
          <div className="dc-games-ctn">
            {andarBahaarList && andarBahaarList.length > 0 ? (
              <>
                <IonRow className="dc-games-ctn">
                  {andarBahaarList.map((g, i) => (
                    <>
                      {filteredProvider &&
                      g.subProviderName?.toLowerCase() !==
                        filteredProvider?.toLowerCase() ? null : (
                        <IonCol
                          sizeXs="6"
                          sizeSm="6"
                          sizeMd="2"
                          sizeLg="2"
                          key={g.gameName}
                          className="dc-ion-col"
                        >
                          <div
                            className="container1"
                            onClick={() =>
                              handleGameClick(
                                g.gameId,
                                g.gameName,
                                g.gameCode,
                                g.subProviderName,
                                g.providerName
                              )
                            }
                          >
                            <img
                              src={
                                g.urlThumb
                              }
                              alt={g.gameName}
                              className="dc-img"
                            />
                            <GetMinAndMaxValues game={g}/>
                            <div className="game-name">
                              <span className="name web-view">
                                {g?.gameName.length > 19
                                  ? g?.gameName?.slice(0, 20) + '..'
                                  : g?.gameName}
                              </span>
                              <span className="name mob-view">
                                {g?.gameName.length > 15
                                  ? g?.gameName?.slice(0, 15) + '..'
                                  : g?.gameName}
                              </span>
                              <span className="p-name">
                                {g.subProviderName}
                              </span>
                              {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                            </div>
                          </div>
                        </IonCol>
                      )}
                    </>
                  ))}
                </IonRow>
              </>
            ) : (
              <div className="no-data">No games found</div>
            )}
          </div>
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={3}>
          {blackjackList && blackjackList.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {blackjackList.map((g, i) => (
                  <>
                    {filteredProvider &&
                    g.subProviderName?.toLowerCase() !==
                      filteredProvider?.toLowerCase() ? null : (
                      <IonCol
                        sizeXs="6"
                        sizeSm="6"
                        sizeMd="2"
                        sizeLg="2"
                        key={g.gameName}
                        className="dc-ion-col"
                      >
                        <div
                          className="container1"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img
                            src={
                              g.urlThumb
                            }
                            alt={g.gameName}
                            className="dc-img"
                          />
                          <GetMinAndMaxValues game={g}/>
                          <div className="game-name">
                            <span className="name web-view">
                              {g?.gameName.length > 19
                                ? g?.gameName?.slice(0, 20) + '..'
                                : g?.gameName}
                            </span>
                            <span className="name mob-view">
                              {g?.gameName.length > 15
                                ? g?.gameName?.slice(0, 15) + '..'
                                : g?.gameName}
                            </span>
                            <span className="p-name">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                      </IonCol>
                    )}
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={5}>
          {baccaratList && baccaratList.length > 0 ? (
            <>
              {/* <h1>Baccarat</h1> */}
              <IonRow className="dc-games-ctn">
                {baccaratList.map((g, i) => (
                  <>
                    {filteredProvider &&
                    g.subProviderName?.toLowerCase() !==
                      filteredProvider?.toLowerCase() ? null : (
                      <IonCol
                        sizeXs="6"
                        sizeSm="6"
                        sizeMd="2"
                        sizeLg="2"
                        key={g.gameName}
                        className="dc-ion-col"
                      >
                        <div
                          className="container1"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img
                            src={g.urlThumb}
                            alt={g.gameName}
                            className="dc-img"
                          />
                          <GetMinAndMaxValues game={g}/>
                          <div className="game-name">
                            <span className="name web-view">
                              {g?.gameName.length > 19
                                ? g?.gameName?.slice(0, 20) + '..'
                                : g?.gameName}
                            </span>
                            <span className="name mob-view">
                              {g?.gameName.length > 15
                                ? g?.gameName?.slice(0, 15) + '..'
                                : g?.gameName}
                            </span>
                            <span className="p-name">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                      </IonCol>
                    )}
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={4}>
          {pokerList && pokerList.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {pokerList.map((g, i) => (
                  <>
                    {filteredProvider &&
                    g.subProviderName?.toLowerCase() !==
                      filteredProvider?.toLowerCase() ? null : (
                      <IonCol
                        sizeXs="6"
                        sizeSm="6"
                        sizeMd="2"
                        sizeLg="2"
                        key={g.gameName}
                        className="dc-ion-col"
                      >
                        <div
                          className="container1"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img
                            src={
                              g.urlThumb
                            }
                            alt={g.gameName}
                            className="dc-img"
                          />
                          <GetMinAndMaxValues game={g}/>
                          <div className="game-name">
                            <span className="name web-view">
                              {g?.gameName.length > 19
                                ? g?.gameName?.slice(0, 20) + '..'
                                : g?.gameName}
                            </span>
                            <span className="name mob-view">
                              {g?.gameName.length > 15
                                ? g?.gameName?.slice(0, 15) + '..'
                                : g?.gameName}
                            </span>
                            <span className="p-name">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                      </IonCol>
                    )}
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={2}>
          {rouletteList && rouletteList.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {rouletteList.map((g, i) => (
                  <>
                    {filteredProvider &&
                    g.subProviderName?.toLowerCase() !==
                      filteredProvider?.toLowerCase() ? null : (
                      <IonCol
                        sizeXs="6"
                        sizeSm="6"
                        sizeMd="2"
                        sizeLg="2"
                        key={g.gameName}
                        className="dc-ion-col"
                      >
                        <div
                          className="container1"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img
                            src={
                              g.urlThumb
                            }
                            alt={g.gameName}
                            className="dc-img"
                          />
                          <GetMinAndMaxValues game={g}/>
                          <div className="game-name">
                            <span className="name web-view">
                              {g?.gameName.length > 19
                                ? g?.gameName?.slice(0, 20) + '..'
                                : g?.gameName}
                            </span>
                            <span className="name mob-view">
                              {g?.gameName.length > 15
                                ? g?.gameName?.slice(0, 15) + '..'
                                : g?.gameName}
                            </span>
                            <span className="p-name">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                      </IonCol>
                    )}
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>


        <TabPanel value={casinoGameCatTabValue} index={6}>
          {crashGamesList && crashGamesList.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {crashGamesList.map((g, i) => (
                  <>
                    {filteredProvider &&
                    g.subProviderName?.toLowerCase() !==
                      filteredProvider?.toLowerCase() ? null : (
                      <IonCol
                        sizeXs="6"
                        sizeSm="6"
                        sizeMd="2"
                        sizeLg="2"
                        key={g.gameName}
                        className="dc-ion-col"
                      >
                        <div
                          className="container1"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img
                            src={
                              g.urlThumb
                            }
                            alt={g.gameName}
                            className="dc-img"
                          />
                          <GetMinAndMaxValues game={g}/>
                          <div className="game-name">
                            <span className="name web-view">
                              {g?.gameName.length > 19
                                ? g?.gameName?.slice(0, 20) + '..'
                                : g?.gameName}
                            </span>
                            <span className="name mob-view">
                              {g?.gameName.length > 15
                                ? g?.gameName?.slice(0, 15) + '..'
                                : g?.gameName}
                            </span>
                            <span className="p-name">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                      </IonCol>
                    )}
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>
        <TabPanel value={casinoGameCatTabValue} index={7}>
          {teenpattiGamesList && teenpattiGamesList.length > 0 ? (
            <>
              {/* <h1>Other</h1> */}
              <IonRow className="dc-games-ctn">
                {teenpattiGamesList.map((g, i) => (
                  <>
                    {filteredProvider &&
                    g.subProviderName?.toLowerCase() !==
                      filteredProvider?.toLowerCase() ? null : (
                      <IonCol
                        sizeXs="6"
                        sizeSm="6"
                        sizeMd="2"
                        sizeLg="2"
                        key={g.gameName}
                        className="dc-ion-col"
                      >
                        <div
                          className="container1"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img
                            src={
                              g.urlThumb
                            }
                            alt={g.gameName}
                            className="dc-img"
                          />
                          <GetMinAndMaxValues game={g}/>
                          <div className="game-name">
                            <span className="name web-view">
                              {g?.gameName.length > 19
                                ? g?.gameName?.slice(0, 20) + '..'
                                : g?.gameName}
                            </span>
                            <span className="name mob-view">
                              {g?.gameName.length > 15
                                ? g?.gameName?.slice(0, 15) + '..'
                                : g?.gameName}
                            </span>
                            <span className="p-name">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                      </IonCol>
                    )}
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>
        <TabPanel value={casinoGameCatTabValue} index={8}>
          {otherList && otherList.length > 0 ? (
            <>
              {/* <h1>Other</h1> */}
              <IonRow className="dc-games-ctn">
                {otherList.map((g, i) => (
                  <>
                    {filteredProvider &&
                    g.subProviderName?.toLowerCase() !==
                      filteredProvider?.toLowerCase() ? null : (
                      <IonCol
                        sizeXs="6"
                        sizeSm="6"
                        sizeMd="2"
                        sizeLg="2"
                        key={g.gameName}
                        className="dc-ion-col"
                      >
                        <div
                          className="container1"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img
                            src={
                              g.urlThumb
                            }
                            alt={g.gameName}
                            className="dc-img"
                          />
                          <GetMinAndMaxValues game={g}/>
                          <div className="game-name">
                            <span className="name web-view">
                              {g?.gameName.length > 19
                                ? g?.gameName?.slice(0, 20) + '..'
                                : g?.gameName}
                            </span>
                            <span className="name mob-view">
                              {g?.gameName.length > 15
                                ? g?.gameName?.slice(0, 15) + '..'
                                : g?.gameName}
                            </span>
                            <span className="p-name">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                      </IonCol>
                    )}
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={9}>
          {betGamesList && betGamesList.length > 0 ? (
            <>
              {/* <h1>Other</h1> */}
              <IonRow className="dc-games-ctn">
                {betGamesList.map((g, i) => (
                  <>
                    {filteredProvider &&
                    g.subProviderName?.toLowerCase() !==
                      filteredProvider.toLowerCase() ? null : (
                      <IonCol
                        sizeXs="6"
                        sizeSm="6"
                        sizeMd="2"
                        sizeLg="2"
                        key={g.gameName}
                        className="dc-ion-col"
                      >
                        <div
                          className="container1"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img
                            src={
                              g.urlThumb
                            }
                            alt={g.gameName}
                            className="dc-img"
                          />
                          <GetMinAndMaxValues game={g}/>
                          <div className="game-name">
                            <span className="name web-view">
                              {g?.gameName.length > 19
                                ? g?.gameName?.slice(0, 20) + '..'
                                : g?.gameName}
                            </span>
                            <span className="name mob-view">
                              {g?.gameName.length > 15
                                ? g?.gameName?.slice(0, 15) + '..'
                                : g?.gameName}
                            </span>
                            <span className="p-name">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                      </IonCol>
                    )}
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={10}>
          {virtualList && virtualList.length > 0 ? (
            <>
              {/* <h1>Other</h1> */}
              <IonRow className="dc-games-ctn">
                {virtualList.map((g, i) => (
                  <>
                    <IonCol
                      sizeXs="6"
                      sizeSm="6"
                      sizeMd="2"
                      sizeLg="2"
                      key={g.gameName}
                      className="dc-ion-col"
                    >
                      <div
                        className="container1"
                        onClick={() =>
                          handleGameClick(
                            g.gameId,
                            g.gameName,
                            g.gameCode,
                            g.subProviderName,
                            g.providerName
                          )
                        }
                      >
                        <img
                          src={
                            g.urlThumb
                          }
                          alt={g.gameName}
                          className="dc-img"
                        />
                        <GetMinAndMaxValues game={g}/>
                        <div className="game-name">
                          <span className="name web-view">
                            {g?.gameName.length > 19
                              ? g?.gameName?.slice(0, 20) + '..'
                              : g?.gameName}
                          </span>
                          <span className="name mob-view">
                            {g?.gameName.length > 15
                              ? g?.gameName?.slice(0, 15) + '..'
                              : g?.gameName}
                          </span>
                          <span className="p-name">{g.subProviderName}</span>
                          {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                        </div>
                      </div>
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={10}>
          {aeSexy && aeSexy.length > 0 ? (
            <>
              {/* <h1>Other</h1> */}
              <IonRow className="dc-games-ctn">
                {aeSexy.map((g, i) => (
                  <>
                    <IonCol
                      sizeXs="6"
                      sizeSm="6"
                      sizeMd="2"
                      sizeLg="2"
                      key={g.gameName}
                      className="dc-ion-col"
                    >
                      <div
                        className="container1"
                        onClick={() =>
                          handleGameClick(
                            g.gameId,
                            g.gameName,
                            g.gameCode,
                            g.subProviderName,
                            g.providerName
                          )
                        }
                      >
                        <img
                          src={
                            g.urlThumb
                          }
                          alt={g.gameName}
                          className="dc-img"
                        />
                        <div className="game-name">
                          <span className="name web-view">
                            {g?.gameName.length > 19
                              ? g?.gameName?.slice(0, 20) + '..'
                              : g?.gameName}
                          </span>
                          <span className="name mob-view">
                            {g?.gameName.length > 15
                              ? g?.gameName?.slice(0, 15) + '..'
                              : g?.gameName}
                          </span>
                          <span className="p-name">{g.subProviderName}</span>
                          {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                        </div>
                      </div>
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </>
          ) : (
            <div className="no-data">No games found</div>
          )}
        </TabPanel>
      </div>
      <Copyright />

      {/* <Footer /> */}

      <Backdrop className="backdrop-ctn" open={showDialog}>
        <LoginModal
          open={showDialog}
          closeHandler={() => setDialogShow(false)}
          noTitle={true}
          size="sm"
          customClass={'login-modal-ctn'}
        >
          <LoginForm modalCloseHandler={() => setDialogShow(false)}></LoginForm>
        </LoginModal>
      </Backdrop>

      {/* <Dialog
        open={infoDialog}
        onClose={() => setInfoDialog(false)}
        aria-labelledby="form-dialog-title"
        className="dialog-div"
      >
        <div className="dialog-div-body">
          <div className="go-corner" onClick={() => setInfoDialog(false)}>
            <div className="go-arrow">
              <ClearIcon />
            </div>
          </div>
          <div className="dialog-title">
            <StarBorderRoundedIcon />
            Welcome
            <StarBorderRoundedIcon />
          </div>

          {getCurrencyTypeFromToken() === 0 ? (
            <div className="dialog-body">
              1 Live casino point is equal to 10 normal points
              <div className="dialog-ex">
                {' '}
                Ex: 100 casino points = 1000 normal points
              </div>
              <p className="dialog-ex">
                Maximum Bet limit is 50000 casino points
              </p>
            </div>
          ) : null}

          {getCurrencyTypeFromToken() === 1 ? (
            <>
              {' '}
              <div className="dialog-body">
                <div className="dialog-ex">
                  {' '}
                  Maximum Bet Limit For All Games is 5000
                </div>
              </div>
            </>
          ) : null}

          <div
            className="dialog-continue"
            onClick={() => {
              getGameUrl(
                selectedEvolutionGame?.gameId,
                selectedEvolutionGame?.gameName,
                selectedEvolutionGame?.gameCode,
                selectedEvolutionGame?.provider,
                selectedEvolutionGame?.subProvider
              );
            }}
          >
            <Button className="btn">Continue</Button>
          </div>
        </div>
      </Dialog> */}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
    liveCasinoGames: EzugiChanges[BRAND_NAME.toLocaleLowerCase()]
    ? state.indianCasino.liveCasinoGames.filter(
        (game) =>
          game.subProviderName.toLowerCase() === 'royal gaming' ||
          game.subProviderName.toLowerCase() === 'spribe'
      )
    : state.indianCasino.liveCasinoGames.filter((game) => game.category !== "Virtual" && game),
    scroll: state.common.scroll,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    FatchLiveCasinoGames: (type: string, providerName:string ,subProviderName:string) =>
      dispatch(FatchLiveCasinoGames(type, providerName, subProviderName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveCasino);
