import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IonCol, IonRow } from '@ionic/react';
import { RootState } from '../../models/RootState';
// import deposit from '../../assets/images/common/icons/deposit.png';
// import gpay from '../../assets/images/common/icons/gpay.svg';
// import paytm from '../../assets/images/common/icons/paytm.svg';
// import phonepay from '../../assets/images/common/icons/phonepay.svg';
import TabPanel from '../../components/TabPanel/TabPanel';
import { Tabs, Tab, Button, TextField } from '@material-ui/core';
// import bank from '../../assets/images/common/icons/bank.svg';
import './Payment.scss';
import './Deposit.scss';
import NumberFormat from 'react-number-format';
import { Label } from '@material-ui/icons';
import AGPAY_API from '../../api-services/agpay-api';
import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import Spinner from '../../components/Spinner/Spinner';
import { setOpenDepositModal } from '../../store';
import { AccountDetails } from './AccountDetails';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { IonSpinner } from '@ionic/react';

import gpay from '../../assets/images/common/Transactions/gpay.svg';
import bankIcon from '../../assets/images/common/Transactions/bank.svg';
import paytm from '../../assets/images/common/Transactions/paytm.svg';
import phonepay from '../../assets/images/common/Transactions/phonepe.svg';
import QRCode from 'react-qr-code';
import ReportsSideBar from '../../components/ReportsSideBar/ReportsSideBar';
import ZenpayCrypto from './DepositTabPanels/ZenpayCrypto';

type StoreProps = {
  setOpenDepositModal: Function;
};

type PaymentDetails = {
  bank_id: string;
  upi_id: string;
  phone_number: string;
  bank_account_number: string;
  bank_account_holder: string;
  ifs_code: string;
  bank_name: string;
  upi_qr: string;
  upi_intent: string;
};

type PaymentOptions = {
  payment_method: string;
  payment_method_details: PaymentDetails;
};

const Deposit: React.FC<StoreProps> = (props) => {
  const { setOpenDepositModal } = props;
  const [tabValue, setTabValue] = useState<number>(0);
  const [paymentOption, setPaymentOption] = useState<string>('');
  const [accountDetails, setAccountDetails] = useState<AccountDetails[]>();
  const [selectedAccountId, setSelectedAccountId] = useState<string>();
  const [depositAmount, setDepositAmount] = useState<string>();
  const [depositNotes, setDepositNotes] = useState<string>();
  const [referenceId, setReferenceId] = useState<string>();
  const [depositImage, setDepositImage] = useState<string | ArrayBuffer>();
  const [loading, setLoading] = useState<boolean>(false);
  const [onlinePaymentOption, setOnlinePaymentOption] = useState<string>();
  const [mobileNumber, setMobileNumber] = useState<string>();
  const [paymentDetails, setPaymentDetails] = useState<PaymentOptions[]>();
  const [providerRefId, setProviderRefId] = useState<string>();
  const [providersList, setProvidersList] = useState([]);
  const [paymentOptionFilter, setPaymentOptionFilter] =
    useState<string>('IMPS');
  const [uploadImage, setUploadImage] = useState(null);
  const hiddenFileInput = useRef(null);
  const history = useHistory();

  const fetchPaymentMethod = async () => {
    try {
      const response = await AGPAY_API.get(`/agpay/v2/pgman/payment-methods`, {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
        params: {
          admin: true,
          paymentOption:
            paymentOption === 'GPAY' || paymentOption === 'PHONEPE'
              ? 'UPI'
              : paymentOption,
        },
      });
      if (response.status === 200) {
        let accountsArr = [];
        response.data.map((i) => {
          if (!i.disabled) accountsArr.push(i);
        });
        setAccountDetails(accountsArr);
      } else {
      }
    } catch (error) {}
  };

  const getPaymentProviders = async () => {
    setLoading(true);
    const accountId = sessionStorage.getItem('aid');
    try {
      const response = await AGPAY_API.get(
        `/agpay/v2/payment-settings/accounts/${accountId}`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        let providersList = response?.data?.deposit_provider_list;
        setProvidersList(providersList);
        if (providersList?.length > 0) {
          if (providersList[0] === 'ABCMONEY') setTabValue(0);
          else if (providersList[0] === 'PGMAN') setTabValue(1);
          else if (providersList[0] === 'ZENPAY') setTabValue(2);
          else if (providersList[0] === 'ZENPAYCRYPTO') setTabValue(3);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (providersList?.length === 1) {
      if (providersList[0] === 'ABCMONEY') setTabValue(0);
      else if (providersList[0] === 'PGMAN') setTabValue(1);
      else if (providersList[0] === 'ZENPAY') setTabValue(2);
      else if (providersList[0] === 'ZENPAYCRYPTO') setTabValue(3);
    }
  }, [providersList]);

  useEffect(() => {
    setDepositNotes('');
    setDepositAmount('');
    setAccountDetails([]);
    setPaymentOption('');
    setSelectedAccountId('');
  }, [tabValue]);

  useEffect(() => {
    getPaymentProviders();
  }, [])

  const submitOnlinePayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = {
        amount: Number(depositAmount),
        notes: depositNotes,
        gatewayProvider: 'PAY5',
        currencyType: 'INR',
        userName: sessionStorage.getItem('username'),
      };
      const response = await AGPAY_API.post(
        `/agpay/v1/online/:charge`,
        payload,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200) {
        toast.success(
          <div>
            <SuccessIcon /> {'Redirectting to payment page!'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

        window.location.href = response.data.paymentUrl;
        // history.push('/my_transactions')
        // setOpenDepositModal(false);
      } else {
        toast.error(
          <div>
            <ErrorIcon /> {response?.data?.message}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        <div>
          <ErrorIcon /> {error?.response?.data?.message}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setLoading(false);
    }
  };

  const submitPayment = async (e) => {
    e.preventDefault();
    if (!depositImage) {
      toast.error(
        <div>
          <ErrorIcon /> {'Please Upload Transaction Image'}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return false;
    } else if (Number(depositAmount) < 100) {
      toast.error(
        <div>
          <ErrorIcon /> {'Deposit Amount cannot be less than 100'}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return false;
    }
    setLoading(true);
    try {
      const payload = {
        amount: Number(depositAmount),
        notes: depositNotes,
        reference_id: referenceId,
        payment_method_id: selectedAccountId,
        payment_option:
          paymentOption === 'NEFT'
            ? 'NEFT'
            : paymentOption === 'UPI'
            ? 'UPI'
            : 'PAYTM_WALLET',
      };
      let formData = new FormData();
      formData.append('paymentSlip', uploadImage);
      formData.append('request', JSON.stringify(payload));
      const response = await AGPAY_API.post(
        `/agpay/v2/pgman/transactions/:deposit`,
        formData,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 204) {
        toast.success(
          <div>
            <SuccessIcon /> {'Transaction Saved Successfully!'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        history.push('/my_transactions');
        setOpenDepositModal(false);
      } else {
        toast.error(
          <div>
            <ErrorIcon /> {response?.data?.message}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        <div>
          <ErrorIcon /> {error?.response?.data?.message}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setLoading(false);
    }
  };

  const handleCapture = ({ target }) => {
    setUploadImage(target.files[0]);
    const fileReader = new FileReader();
    const name = target.accept.includes('image') ? 'images' : 'videos';
    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      setDepositImage(e.target.result);
    };
  };

  const submitOnlineAmount = async (e, selectedPaymentGateway = 'abcmoney') => {
    e.preventDefault();
    if (mobileNumber?.length != 10) {
      toast.error('Invalid Mobile Number');
      return false;
    }
    setLoading(true);

    try {
      const payload = {
        amount: Number(depositAmount),
        currency_type: 'INR',
        mobile_number: mobileNumber,
        notes: depositNotes,
        payment_method:
          onlinePaymentOption === 'UPI' ? 'UPI_TRANSFER' : onlinePaymentOption,
        upi_intent: onlinePaymentOption === 'UPI',
        upi_qr: onlinePaymentOption === 'UPI',
      };
      const response = await AGPAY_API.post(
        `/agpay/v2/${selectedPaymentGateway}/transactions/:deposit`,
        payload,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200) {
        if (response.data.payment_options?.length > 0) {
          console.log(response.data.payment_options);
          setPaymentDetails(response.data.payment_options);
          setProviderRefId(response.data.provider_ref_id);
        }
        toast.success(
          <div>
            <SuccessIcon /> {'Transaction Saved Successfully!'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        <div>
          <ErrorIcon /> {error?.response?.data?.message}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setLoading(false);
    }
  };

  const confirmPayment = async (e, selectedCurrency = 'abcmoney') => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        gateway_provider_reference_id: providerRefId,
        mobile_number: mobileNumber,
        bank_id: paymentDetails?.filter(
          (i) => i?.payment_method === onlinePaymentOption
        )[0].payment_method_details?.bank_id,
        utr: referenceId,
        upi_intent: !!paymentDetails?.filter(
          (i) => i?.payment_method === onlinePaymentOption
        )[0]?.payment_method_details?.upi_intent,
        payment_option:
          onlinePaymentOption === 'BANK_TRANSFER' ? 'IMPS' : 'UPI',
      };
      let formData = new FormData();
      formData.append('paymentSlip', uploadImage);
      formData.append('request', JSON.stringify(payload));
      const response = await AGPAY_API.post(
        `/agpay/v2/${selectedCurrency}/:confirm-payment`,
        selectedCurrency == 'abcmoney' ? formData : payload,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status == 200) {
        toast.success(
          <div>
            <SuccessIcon /> {'Transaction Saved Successfully!'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        history.push('/my_transactions');
        setOpenDepositModal(false);
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        <div>
          <ErrorIcon /> {error?.response?.data?.message}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setLoading(false);
    }
  };

  const copyText = (text,toastMsg = "Text Copied!") => {
    navigator.clipboard.writeText(text);
    toast.success(toastMsg);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (paymentOption) {
      fetchPaymentMethod();
      setAccountDetails([]);
      setSelectedAccountId('');
      setDepositAmount('');
      setDepositImage('');
      setDepositNotes('');
      setReferenceId('');
    }
  }, [paymentOption]);

  return (
    <div className="reports-ctn payment-deposit-ctn deposit-background-ctn">
      {loading ? <Spinner /> : null}
      <div className="header-ctn deposits-header">
        <IonRow>
          {/* <IonCol sizeMd="1.7" sizeLg="1.7" className="web-view">
          <div className="compt-menu-section">
            <div className="sticky-col">
              <ReportsSideBar />
            </div>
          </div>
        </IonCol> */}
          <IonCol>
            <div className="deposit-form-ctn">
              {providersList?.length > 1 && (
                <Tabs
                  value={tabValue}
                  onChange={(_, newValue) => {
                    setTabValue(newValue);
                  }}
                >
                  {providersList?.includes('ABCMONEY') && (
                    <Tab value={0} label="Option 1" className="payment-btn" />
                  )}
                  {providersList?.includes('PGMAN') && (
                    <Tab value={1} label="Option 2" className="payment-btn" />
                  )}
                  {providersList?.includes('ZENPAY') && (
                    <Tab value={2} label="Option 3" className="payment-btn" />
                  )}
                  {providersList?.includes('ZENPAYCRYPTO') && (
                    <Tab value={3} label="Crypto" className="payment-btn" />
                  )}
                </Tabs>
              )}

              <TabPanel value={tabValue} index={0}>
                {providersList?.length > 0 ? (
                  <>
                    <div className="payment-option-title mt-10">
                      Choose Payment Option
                    </div>
                    <Tabs
                      value={onlinePaymentOption}
                      onChange={(_, newValue) => {
                        setOnlinePaymentOption(newValue);
                      }}
                    >
                      <Tab value={'BANK_TRANSFER'} label="Bank" />
                      <Tab value={'UPI'} label="UPI" />
                    </Tabs>
                    {onlinePaymentOption && (
                      <form
                        className="account-inputs"
                        onSubmit={(e) => submitOnlineAmount(e)}
                      >
                        <div className="account-input">
                          <div className="account-label">Enter Amount</div>
                          <NumberFormat
                            required
                            className={
                              paymentDetails?.length &&
                              paymentDetails[0]?.payment_method
                                ? 'disabled acc-input'
                                : 'acc-input'
                            }
                            placeholder="Enter Amount to be Deposited"
                            value={depositAmount}
                            onChange={(e) => setDepositAmount(e.target.value)}
                            decimalSeparator={false}
                            disabled={
                              paymentDetails?.length &&
                              paymentDetails[0]?.payment_method
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="account-input">
                          <div className="account-label">
                            Enter Mobile Number
                          </div>
                          <NumberFormat
                            required
                            className={
                              paymentDetails?.length &&
                              paymentDetails[0]?.payment_method
                                ? 'disabled acc-input'
                                : 'acc-input'
                            }
                            placeholder="Enter Mobile Number"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            decimalSeparator={false}
                            disabled={
                              paymentDetails?.length &&
                              paymentDetails[0]?.payment_method
                                ? true
                                : false
                            }
                          />
                        </div>
                        {!providerRefId && (
                          <Button
                            className="submit-payment-btn"
                            type="submit"
                            disabled={loading ? true : false}
                            endIcon={
                              loading ? <IonSpinner name="lines-small" /> : ''
                            }
                          >
                            Next
                          </Button>
                        )}
                      </form>
                    )}

                    {paymentDetails?.length &&
                      paymentDetails[0]?.payment_method && (
                        <form
                          className="account-inputs"
                          onSubmit={(e) => confirmPayment(e)}
                        >
                          <div className="payment-option-title mt-10">
                            Account Details
                          </div>

                          {paymentDetails?.length &&
                            paymentDetails[0]?.payment_method &&
                            onlinePaymentOption === 'BANK_TRANSFER' && (
                              <div className="account-inputs">
                                <div className="account-input">
                                  <div className="payment-detail">
                                    Account Holder Name:
                                    <span
                                      title="Click to Copy"
                                      className="payment-detail-input"
                                      onClick={() =>
                                        copyText(
                                          paymentDetails?.filter(
                                            (i) =>
                                              i?.payment_method ===
                                              onlinePaymentOption
                                          )[0]?.payment_method_details
                                            ?.bank_account_holder
                                        )
                                      }
                                    >
                                      {
                                        paymentDetails?.filter(
                                          (i) =>
                                            i?.payment_method ===
                                            onlinePaymentOption
                                        )[0]?.payment_method_details
                                          ?.bank_account_holder
                                      }
                                    </span>
                                  </div>
                                  <div className="payment-detail">
                                    Account Holder Number:
                                    <span
                                      title="Click to Copy"
                                      className="payment-detail-input"
                                      onClick={() =>
                                        copyText(
                                          paymentDetails?.filter(
                                            (i) =>
                                              i?.payment_method ===
                                              onlinePaymentOption
                                          )[0]?.payment_method_details
                                            ?.bank_account_number
                                        )
                                      }
                                    >
                                      {
                                        paymentDetails?.filter(
                                          (i) =>
                                            i?.payment_method ===
                                            onlinePaymentOption
                                        )[0]?.payment_method_details
                                          ?.bank_account_number
                                      }
                                    </span>
                                  </div>
                                  <div className="payment-detail">
                                    Account IFSC Code:
                                    <span
                                      title="Click to Copy"
                                      className="payment-detail-input"
                                      onClick={() =>
                                        copyText(
                                          paymentDetails?.filter(
                                            (i) =>
                                              i?.payment_method ===
                                              onlinePaymentOption
                                          )[0]?.payment_method_details?.ifs_code
                                        )
                                      }
                                    >
                                      {
                                        paymentDetails?.filter(
                                          (i) =>
                                            i?.payment_method ===
                                            onlinePaymentOption
                                        )[0]?.payment_method_details?.ifs_code
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          {onlinePaymentOption === 'UPI' && (
                            <div className="account-inputs">
                              <div className="account-input">
                                <div
                                  style={{
                                    height: 'auto',
                                    margin: '0 auto 10px',
                                    maxWidth: 128,
                                    width: '100%',
                                  }}
                                >
                                  <QRCode
                                    size={256}
                                    style={{
                                      height: 'auto',
                                      maxWidth: '100%',
                                      width: '100%',
                                    }}
                                    value={`upi://pay?pa=${
                                      paymentDetails?.filter(
                                        (i) =>
                                          i?.payment_method ===
                                          onlinePaymentOption
                                      )[0]?.payment_method_details?.upi_id
                                    }&am=${depositAmount}&tn=pay to eswar&cu=INR&pn=${
                                      paymentDetails?.filter(
                                        (i) =>
                                          i?.payment_method ===
                                          onlinePaymentOption
                                      )[0].payment_method_details
                                        .bank_account_holder
                                    }`}
                                    viewBox={`0 0 256 256`}
                                  />
                                </div>
                                <div className="payment-detail">
                                  UPI ID:
                                  <span
                                    title="Click to Copy"
                                    className="payment-detail-input"
                                    onClick={() =>
                                      copyText(
                                        paymentDetails?.filter(
                                          (i) =>
                                            i?.payment_method ===
                                            onlinePaymentOption
                                        )[0]?.payment_method_details?.upi_id
                                      )
                                    }
                                  >
                                    {
                                      paymentDetails?.filter(
                                        (i) =>
                                          i?.payment_method ===
                                          onlinePaymentOption
                                      )[0]?.payment_method_details?.upi_id
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="account-input">
                            <div className="account-label">
                              Enter Reference ID/UTR
                            </div>
                            <TextField
                              required
                              className="acc-input"
                              placeholder="Enter Reference ID/UTR"
                              value={referenceId}
                              onChange={(e) => setReferenceId(e.target.value)}
                            />
                          </div>
                          <div className="account-input">
                            {depositImage ? (
                              <>
                                <div>
                                  <div className="uploaded-image-title">
                                    Uploaded Image
                                  </div>
                                  <img
                                    src={`${depositImage}`}
                                    className="deposit-image"
                                  />
                                </div>
                              </>
                            ) : null}

                            <input
                              accept="image/*"
                              style={{ display: 'none' }}
                              id="raised-button-file"
                              multiple
                              hidden
                              type="file"
                              onChange={(e) => handleCapture(e)}
                            />
                            <label htmlFor="raised-button-file">
                              <Button component="div" className="upload-btn">
                                Upload Image
                              </Button>
                            </label>
                          </div>
                          <Button
                            className="submit-payment-btn"
                            type="submit"
                            disabled={loading ? true : false}
                            endIcon={
                              loading ? <IonSpinner name="lines-small" /> : ''
                            }
                          >
                            Confirm Payment
                          </Button>
                        </form>
                      )}
                  </>
                ) : (
                  <div>No Providers Available</div>
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={1} className="payment-options">
                <div className="payment-option-title mt-10">
                  Choose Payment Option
                </div>
                <Button
                  className={
                    paymentOption === 'NEFT'
                      ? 'active payment-btn'
                      : 'payment-btn'
                  }
                  onClick={() => setPaymentOption('NEFT')}
                >
                  <img
                    className={'payment-icon'}
                    alt="bank"
                    src={bankIcon}
                    loading="lazy"
                  />
                </Button>
                <Button
                  className={
                    paymentOption === 'GPAY'
                      ? 'active payment-btn'
                      : 'payment-btn'
                  }
                  onClick={() => setPaymentOption('GPAY')}
                >
                  <img
                    className={'payment-icon'}
                    alt="gpay"
                    src={gpay}
                    loading="lazy"
                  />
                </Button>
                <Button
                  className={
                    paymentOption === 'PHONEPE'
                      ? 'active payment-btn'
                      : 'payment-btn'
                  }
                  onClick={() => setPaymentOption('PHONEPE')}
                >
                  <img
                    className={'payment-icon'}
                    alt="phonepay"
                    src={phonepay}
                    loading="lazy"
                  />
                </Button>
                <Button
                  className={
                    paymentOption === 'PAYTM_WALLET'
                      ? 'active payment-btn'
                      : 'payment-btn'
                  }
                  onClick={() => setPaymentOption('PAYTM_WALLET')}
                >
                  <img
                    className={'payment-icon'}
                    alt="paytm"
                    src={paytm}
                    loading="lazy"
                    height={30}
                  />
                </Button>

                <div>
                  {accountDetails?.length > 0 ? (
                    <>
                      {/* <div className="payment-form-divider"></div> */}
                      <div className="account-details-title account-mt-10">
                        Choose Beneficiary Account
                      </div>
                    </>
                  ) : null}
                  {paymentOption && accountDetails?.length == 0 ? (
                    <>
                      <div className="account-details-title">
                        No Beneficiary Account Available
                      </div>
                    </>
                  ) : null}
                  <div className="account-details-ctn">
                    <Tabs
                      value={1}
                      variant="scrollable"
                      scrollButtons="off"
                      className="actions-list"
                    >
                      {accountDetails?.map((acc) =>
                        !acc.disabled ? (
                          <>
                            <Button
                              className={
                                acc?.id?.toString() === selectedAccountId
                                  ? 'active account-detail'
                                  : 'account-detail'
                              }
                              onClick={() =>
                                setSelectedAccountId(acc?.id?.toString())
                              }
                            >
                              <div className="account-name">
                                {acc?.paymentMethodDetails?.accountHolderName}
                              </div>
                              <div className="account-number">
                                {acc?.paymentMethodDetails?.accountNumber}
                              </div>
                              <div className="account-number">
                                {acc?.paymentMethodDetails?.upiId}
                              </div>
                              <div className="account-number">
                                {acc?.paymentMethodDetails?.phoneNumber}
                              </div>
                              {paymentOption === 'NEFT' && (
                                <div className="account-ifsc">
                                  IFSC: {acc?.paymentMethodDetails?.ifscCode}
                                </div>
                              )}
                            </Button>
                          </>
                        ) : null
                      )}
                    </Tabs>
                  </div>
                </div>

                {selectedAccountId ? (
                  <>
                    <div className="payment-form-divider"></div>

                    <form
                      className="account-inputs"
                      onSubmit={(e) => submitPayment(e)}
                    >
                      <div className="account-input-title">
                        Enter Payment Details
                      </div>
                      <div className="account-input">
                        <div className="account-label">Enter Amount</div>
                        <NumberFormat
                          required
                          className="acc-input"
                          placeholder="Enter Deposited Amount"
                          value={depositAmount}
                          onChange={(e) => setDepositAmount(e.target.value)}
                          decimalSeparator={false}
                        />
                      </div>
                      <div className="account-input">
                        <div className="account-label">Enter Notes</div>
                        <TextField
                          required
                          placeholder="Enter Notes"
                          className="acc-input"
                          value={depositNotes}
                          onChange={(e) => setDepositNotes(e.target.value)}
                        />
                      </div>

                      <div className="account-input">
                        <div className="account-label">Enter Reference ID</div>
                        <TextField
                          placeholder="Enter Transaction Reference ID (optional)"
                          className="acc-input"
                          value={referenceId}
                          onChange={(e) => setReferenceId(e.target.value)}
                        />
                      </div>

                      <div className="account-input">
                        {depositImage ? (
                          <>
                            <div>
                              <div className="uploaded-image-title">
                                Uploaded Image
                              </div>
                              <img
                                src={`${depositImage}`}
                                className="deposit-image"
                              />
                            </div>
                          </>
                        ) : null}

                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="raised-button-file"
                          multiple
                          hidden
                          type="file"
                          onChange={(e) => handleCapture(e)}
                        />
                        <label htmlFor="raised-button-file">
                          <Button component="div" className="upload-btn">
                            Upload Image
                          </Button>
                        </label>
                      </div>
                      <Button
                        className="submit-payment-btn"
                        type="submit"
                        disabled={loading ? true : false}
                        endIcon={
                          loading ? <IonSpinner name="lines-small" /> : ''
                        }
                      >
                        Submit
                      </Button>
                    </form>
                  </>
                ) : null}
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {providersList?.length > 0 ? (
                  <>
                    <div className="payment-option-title mt-10">
                      Choose Payment Option
                    </div>
                    <Tabs
                      value={onlinePaymentOption}
                      onChange={(_, newValue) => {
                        setOnlinePaymentOption(newValue);
                      }}
                    >
                      <Tab value={'BANK_TRANSFER'} label="Bank" />
                      <Tab value={'UPI'} label="UPI" />
                    </Tabs>
                    {onlinePaymentOption && (
                      <form
                        className="account-inputs"
                        onSubmit={(e) => submitOnlineAmount(e, 'zenpay')}
                      >
                        <div className="account-input">
                          <div className="account-label">Enter Amount</div>
                          <NumberFormat
                            required
                            className={
                              paymentDetails?.length &&
                              paymentDetails[0]?.payment_method
                                ? 'disabled acc-input'
                                : 'acc-input'
                            }
                            placeholder="Enter Amount to be Deposited"
                            value={depositAmount}
                            onChange={(e) => setDepositAmount(e.target.value)}
                            decimalSeparator={false}
                            disabled={
                              paymentDetails?.length &&
                              paymentDetails[0]?.payment_method
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="account-input">
                          <div className="account-label">
                            Enter Mobile Number
                          </div>
                          <NumberFormat
                            required
                            className={
                              paymentDetails?.length &&
                              paymentDetails[0]?.payment_method
                                ? 'disabled acc-input'
                                : 'acc-input'
                            }
                            placeholder="Enter Mobile Number"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            decimalSeparator={false}
                            disabled={
                              paymentDetails?.length &&
                              paymentDetails[0]?.payment_method
                                ? true
                                : false
                            }
                          />
                        </div>
                        {!providerRefId && (
                          <Button
                            className="submit-payment-btn"
                            type="submit"
                            disabled={loading ? true : false}
                            endIcon={
                              loading ? <IonSpinner name="lines-small" /> : ''
                            }
                          >
                            Next
                          </Button>
                        )}
                      </form>
                    )}

                    {paymentDetails?.length > 0 &&
                      paymentDetails[0]?.payment_method && (
                        <form
                          className="account-inputs"
                          onSubmit={(e) => confirmPayment(e, 'zenpay')}
                        >
                          <div className="payment-option-title mt-10">
                            Account Details
                          </div>

                          {paymentDetails?.length > 0 &&
                            paymentDetails[0]?.payment_method &&
                            onlinePaymentOption === 'BANK_TRANSFER' && (
                              <div className="account-inputs">
                                <div className="account-input">
                                  <div className="payment-detail">
                                    Account Holder Name:
                                    <span
                                      title="Click to Copy"
                                      className="payment-detail-input"
                                      onClick={() =>
                                        copyText(
                                          paymentDetails?.filter(
                                            (i) =>
                                              i?.payment_method ===
                                              onlinePaymentOption
                                          )[0]?.payment_method_details
                                            ?.bank_account_holder
                                        )
                                      }
                                    >
                                      {
                                        paymentDetails?.filter(
                                          (i) =>
                                            i?.payment_method ===
                                            onlinePaymentOption
                                        )[0]?.payment_method_details
                                          ?.bank_account_holder
                                      }
                                    </span>
                                  </div>
                                  <div className="payment-detail">
                                    Account Holder Number:
                                    <span
                                      title="Click to Copy"
                                      className="payment-detail-input"
                                      onClick={() =>
                                        copyText(
                                          paymentDetails?.filter(
                                            (i) =>
                                              i?.payment_method ===
                                              onlinePaymentOption
                                          )[0]?.payment_method_details
                                            ?.bank_account_number
                                        )
                                      }
                                    >
                                      {
                                        paymentDetails?.filter(
                                          (i) =>
                                            i?.payment_method ===
                                            onlinePaymentOption
                                        )[0]?.payment_method_details
                                          ?.bank_account_number
                                      }
                                    </span>
                                  </div>
                                  <div className="payment-detail">
                                    Account IFSC Code:
                                    <span
                                      title="Click to Copy"
                                      className="payment-detail-input"
                                      onClick={() =>
                                        copyText(
                                          paymentDetails?.filter(
                                            (i) =>
                                              i?.payment_method ===
                                              onlinePaymentOption
                                          )[0]?.payment_method_details?.ifs_code
                                        )
                                      }
                                    >
                                      {
                                        paymentDetails?.filter(
                                          (i) =>
                                            i?.payment_method ===
                                            onlinePaymentOption
                                        )[0]?.payment_method_details?.ifs_code
                                      }
                                    </span>
                                  </div>
                                  <div className="payment-detail">
                                    Bank Name:
                                    <span
                                      title="Click to Copy"
                                      className="payment-detail-input"
                                      onClick={() =>
                                        copyText(
                                          paymentDetails?.filter(
                                            (i) =>
                                              i?.payment_method ===
                                              onlinePaymentOption
                                          )[0]?.payment_method_details
                                            ?.bank_name
                                        )
                                      }
                                    >
                                      {paymentDetails?.filter(
                                        (i) =>
                                          i?.payment_method ===
                                          onlinePaymentOption
                                      )[0]?.payment_method_details?.bank_name ??
                                        '--'}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          {onlinePaymentOption === 'UPI' && (
                            <div className="account-inputs">
                              <div className="account-input">
                                <div
                                  style={{
                                    height: 'auto',
                                    margin: '0px',
                                    maxWidth: 128,
                                    width: '100%',
                                  }}
                                >
                                  <QRCode
                                    size={256}
                                    style={{
                                      height: 'auto',
                                      maxWidth: '100%',
                                      width: '100%',
                                    }}
                                    value={
                                      paymentDetails?.find(
                                        (i) =>
                                          i?.payment_method ===
                                          onlinePaymentOption
                                      )?.payment_method_details?.upi_intent
                                    }
                                    viewBox={`0 0 256 256`}
                                  />
                                </div>
                                {!paymentDetails?.filter(
                                  (i) =>
                                    i?.payment_method === onlinePaymentOption
                                )[0]?.payment_method_details?.upi_intent ? (
                                  <div className="payment-detail">
                                    UPI ID:
                                    <span
                                      title="Click to Copy"
                                      className="payment-detail-input"
                                      onClick={() =>
                                        copyText(
                                          paymentDetails?.filter(
                                            (i) =>
                                              i?.payment_method ===
                                              onlinePaymentOption
                                          )[0]?.payment_method_details?.upi_id
                                        )
                                      }
                                    >
                                      {
                                        paymentDetails?.filter(
                                          (i) =>
                                            i?.payment_method ===
                                            onlinePaymentOption
                                        )[0]?.payment_method_details?.upi_id
                                      }
                                    </span>
                                  </div>
                                ) : null}
                                {paymentDetails?.filter(
                                  (i) =>
                                    i?.payment_method === onlinePaymentOption
                                )[0]?.payment_method_details?.upi_intent ? (
                                  <>
                                    <Button
                                      className={'active payment-btn'}
                                      onClick={(e) => {
                                        confirmPayment(e, 'zenpay');
                                        window.open(
                                          paymentDetails
                                            ?.filter(
                                              (i) =>
                                                i?.payment_method ===
                                                onlinePaymentOption
                                            )[0]
                                            ?.payment_method_details?.upi_intent.replace(
                                              'upi',
                                              'phonepe'
                                            )
                                        )}
                                      }
                                    >
                                      <img
                                        className={'payment-icon'}
                                        alt="phonepay"
                                        src={phonepay}
                                        loading="lazy"
                                      />
                                    </Button>

                                    <Button
                                      className={'active payment-btn'}
                                      onClick={(e) => {
                                        confirmPayment(e, 'zenpay');
                                        window.open(
                                          paymentDetails
                                            ?.filter(
                                              (i) =>
                                                i?.payment_method ===
                                                onlinePaymentOption
                                            )[0]
                                            ?.payment_method_details?.upi_intent.replace(
                                              'upi://',
                                              'tez://upi/'
                                            )
                                        )}
                                      }
                                    >
                                      <img
                                        className={'payment-icon'}
                                        alt="gpay"
                                        src={gpay}
                                        loading="lazy"
                                      />
                                    </Button>

                                    <Button
                                      className={'active payment-btn'}
                                      onClick={(e) => {
                                        confirmPayment(e, 'zenpay');
                                        window.open(
                                          paymentDetails
                                            ?.filter(
                                              (i) =>
                                                i?.payment_method ===
                                                onlinePaymentOption
                                            )[0]
                                            ?.payment_method_details?.upi_intent.replace(
                                              'upi',
                                              'paytmmp'
                                            )
                                        )}
                                      }
                                    >
                                      <img
                                        className={'payment-icon'}
                                        alt="paytm"
                                        src={paytm}
                                        loading="lazy"
                                        height={30}
                                      />
                                    </Button>

                                    <div className="payment-detail">
                                      <a
                                        href={
                                          paymentDetails?.filter(
                                            (i) =>
                                              i?.payment_method ===
                                              onlinePaymentOption
                                          )[0]?.payment_method_details
                                            ?.upi_intent
                                        }
                                      >
                                        Pay through UPI app
                                      </a>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          )}

                          {paymentDetails?.length > 0 &&
                          paymentDetails[0]?.payment_method &&
                          onlinePaymentOption === 'BANK_TRANSFER' ? (
                            <>
                              <div className="account-input">
                                <div className="account-label">
                                  Enter Reference ID/UTR (Re-verify for
                                  correctness)
                                </div>
                                <TextField
                                  required
                                  className="acc-input"
                                  placeholder="Enter Reference ID/UTR"
                                  value={referenceId}
                                  onChange={(e) =>
                                    setReferenceId(e.target.value)
                                  }
                                />
                              </div>

                              <div className="account-input">
                                <div className="account-label">
                                  Select Payment Option
                                </div>
                                <select
                                  required
                                  value={paymentOptionFilter}
                                  placeholder="Select One"
                                  className="acc-input"
                                  onChange={(e) =>
                                    setPaymentOptionFilter(e.target.value)
                                  }
                                >
                                  <option value="IMPS">IMPS</option>
                                  <option value="NEFT">NEFT</option>
                                  <option value="RTGS">RTGS</option>
                                </select>
                              </div>

                              <div className="account-input">
                                {depositImage ? (
                                  <>
                                    <div>
                                      <div className="uploaded-image-title">
                                        Uploaded Image
                                      </div>
                                      <img
                                        src={`${depositImage}`}
                                        className="deposit-image"
                                      />
                                    </div>
                                  </>
                                ) : null}

                                <input
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  id="raised-button-file-abcmoney"
                                  multiple
                                  hidden
                                  type="file"
                                  ref={hiddenFileInput}
                                  onChange={(e) => handleCapture(e)}
                                />
                                <Button
                                  component="div"
                                  className="upload-btn"
                                  onClick={handleClick}
                                >
                                  Upload Image
                                </Button>
                              </div>
                            </>
                          ) : null}

                          <Button
                            className="submit-payment-btn"
                            type="submit"
                            disabled={loading ? true : false}
                            endIcon={
                              loading ? <IonSpinner name="lines-small" /> : ''
                            }
                          >
                            Confirm Payment
                          </Button>
                        </form>
                      )}
                  </>
                ) : (
                  <div>No Providers Available</div>
                )}
              </TabPanel>

              <TabPanel value={tabValue} index={3}> 
                {/* <div className="l">Hello world</div> */}
                <ZenpayCrypto 
                  setLoading={setLoading} 
                  loading={loading} 
                  copyText= {copyText}
                ></ZenpayCrypto>
              </TabPanel>
            </div>
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setOpenDepositModal: (val) => dispatch(setOpenDepositModal(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);
