import React, { useState } from 'react';
import './LoginForm.scss';
import loginimg from '../../assets/images/common/loginbg.png';
import LoginForm from './LoginForm';
import { Tabs, Tab, Button } from '@material-ui/core';
import TabPanel from '../../components/TabPanel/TabPanel';
import SignUp from '../../pages/SignUp/SignUp';
import { IonCol, IonIcon } from '@ionic/react';
import facebook from '../../assets/images/common/facebook.svg';
import google from '../../assets/images/common/google.svg';
import ForgotPwdForm from '../ForgotPassword/ForgotPassword';
import { RootState } from '../../models/RootState';
import { connect } from 'react-redux';
import { DomainConfig } from '../../models/DomainConfig';

type LoginProps = {
  modalCloseHandler: Function;
  tabActive?: number;
  domainConfig: DomainConfig
};

const LoginPage: React.FC<LoginProps> = (props) => {
  const { modalCloseHandler, tabActive, domainConfig } = props;
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [tabValue, setTabValue] = useState<number>(tabActive ? tabActive : 0);
  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="login-ctn">
      {/* <img src={Background} className="background-img" /> */}
      {/* <div className="left-ctn">
        <img src={loginimg} />
      </div> */}
      <div className="right-ctn">
        {/* <Tabs value={tabValue} onChange={handleTabChange} className="tab-list">
          <Tab label="Register" wrapped value={1} />
          <Tab label="Login" wrapped value={0} />
        </Tabs> */}
        {/* {
          tabValue == 1 ?
            <Button onClick={() => handleTabChange(0)}>Login</Button>
            :
            <Button onClick={() => handleTabChange(1)}>Register</Button>

        } */}

        <TabPanel value={tabValue} index={0} className="tab-ctn">
          {/* <div className="header-ctn">
            <div className="dot">
              <IonIcon src={google} className="icon" />
            </div>
            <div className="dot">
              <IonIcon src={facebook} className="icon" />
            </div>
          </div> */}
          {/* <p className="header-title">Or Use Your Email Account</p> */}
          <LoginForm modalCloseHandler={modalCloseHandler} />
        </TabPanel>

        <TabPanel value={tabValue} index={1} className="tab-ctn">
          {/* <div className="header-ctn web-view">
            <div className="dot">
              <IonIcon src={google} className="icon" />
            </div>
            <div className="dot">
              <IonIcon src={facebook} className="icon" />
            </div>
          </div> */}
          {/* <p className="header-title web-view">Or Use Your Email Account</p> */}
          <SignUp setTabValue={setTabValue} modalCloseHandler={modalCloseHandler} />
        </TabPanel>
        <TabPanel value={tabValue} index={2} className="tab-ctn">
          <ForgotPwdForm />
        </TabPanel>
        {tabValue == 0 ? (
          <>
            {domainConfig.signup ? 
            <div className="link pb-50">
            Don't have an account?
            <span
              className="register-link"
              onClick={() => handleTabChange(1)}
            >
              {' '}
              Sign up
            </span>
          </div> : null}
            <div className="link pb-50">
              <span
                className="register-link"
                onClick={() => handleTabChange(2)}
              >
                {' '}
                Forgot password?
              </span>
            </div>
          </>
        ) : (
          // <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12">

          // </IonCol>
          <div className="link pb-50">
            Already have an account?
            <span className="register-link" onClick={() => handleTabChange(0)}>
              {' '}
              Sign in
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    domainConfig: state.common.domainConfig,
  };
};

export default connect(mapStateToProps)(LoginPage);