export const ContactList: { [key: string]: string } = {
  KingsOdd: '+44 7883 309261 | +44 7418 357121',
};

export const ShowCasinoModal: { [key: string]: boolean } = {
  DhanEXCH: false,
};

export const ShowThemeToggle: { [key: string]: boolean } = {
  Bet60Exchange: false,
};

export const ShowSelfRagistration: { [key: string]: boolean } = {
  hypexexch: true,
  // taash247: true,
  royalgaming247: true,
  mazaa: true,
  taash: true,
  quickexch: true,
  wincasino247: true,
};

export const ShowWhatsopIcon: { [key: string]: boolean } = {
  hypexexch: true,
  taash247: true,
  royalgaming247: true,
  mazaa: true,
  taash: true,
  wincasino247: true,
};

export const ShowTelegramIcon: { [key: string]: boolean } = {
  wincasino247: true,
};

export const EzugiChanges: { [key: string]: boolean } = {
  chal247: true,
};
