import { IonCard, IonCardContent } from '@ionic/react';
import { Button, Tab, Tabs, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { CompetitionDTO } from '../../models/common/CompetitionDTO';
import { EventType } from '../../models/EventType';
import { RootState } from '../../models/RootState';
import { SelectedObj } from '../../models/ExchangeSportsState';
import ExpandIcon from "../../assets/images/icons/expandIcon.svg"
import {
    fetchCompetitions,
    getCompetitionsByEventType,
    setCompetition,
    fetchEventsByCompetition,
    setExchEvent,
} from '../../store';
import SVLS_API from '../../svls-api';
import MomentUtils from '@date-io/moment';
import { PROVIDER_ID } from '../../constants/Branding';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { transactionTypes } from '../../util/stringUtil';

type options = [{ name: string; value: string }];

type StoreProps = {
    allowedConfig: number;
    loggedIn: boolean;
    eventTypes: EventType[];
    competitions: CompetitionDTO[];
    selectedEventType: SelectedObj;
    selectedCompetition: SelectedObj;
    setExchEvent: (event: SelectedObj) => void;
    fetchCompetitions: (eventTypeId: string) => void;
    setCompetition: (competition: SelectedObj) => void;
    fetchEventsByCompetition: (
        eventTypeId: string,
        competitionId: string
    ) => void;
    dateFrom: Moment;
    dateTo: Moment;
    setDateTo: Function;
    setLoading: Function;
    setDateFrom: Function;
    setPageToken: Function;
    setTransactionType: Function;
    transactionType: string;
};


type InputProps = {
    eventName: string;
}

const MyBetSidebar: React.FC<StoreProps> = (props) => {
    const {
        competitions,
        selectedEventType,
        selectedCompetition,
        fetchCompetitions,
        setCompetition,
        fetchEventsByCompetition,
        dateFrom,
        dateTo,
        setLoading,
        setDateFrom,
        setDateTo,
        setPageToken,
        transactionType,
        setTransactionType
    } = props;

    const history = useHistory()

    const [tabValue, setTabValue] = useState<number>(0);
    const [subTabValue, setSubTabValue] = useState<number>(0);
    const [eventName, setEventName] = useState<string>("");
    const [eventsData, setEventsData] = useState([]);
    const [showList, setShowList] = useState<string>('')
    const pathParams = useParams();
    const competition = pathParams['competition'];
    const location = useLocation();

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const handleSubTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number
    ) => {
        setSubTabValue(newValue);
    };

    const handleCompetitionChange = (c: CompetitionDTO) => {
        setCompetition({ id: c.id, name: c.name, slug: c.slug });
        fetchEventsByCompetition(selectedEventType.id, c.id);
    };

    useEffect(() => {
        if (!location.pathname.startsWith('/exchange_sports/inplay')) {
            if (!competitions) {
                fetchCompetitions(selectedEventType?.id);
            } else {
                if (competition && selectedCompetition.slug! === competition) {
                    for (let i = 0; i < competitions.length; i += 1) {
                        if (competitions[i].slug === competition && i !== tabValue) {
                            setTabValue(i);
                            handleCompetitionChange(competitions[i]);
                            break;
                        }
                    }
                }
            }
        }
    }, [competitions, selectedCompetition]);

    useEffect(() => {
        if (!competition) {
            setCompetition({ id: '', name: '', slug: '' });
        }
    }, []);
    const getCompetitionName = (name, sportId) => {
        if (sportId === '7' || sportId === '4339') {
            let fisrtName = name?.split('_')[0];
            let lastName = name?.split('_')[1];
            return lastName + '  (' + fisrtName + ') ';
        } else {
            return name;
        }
    };

    const searchEvent = async (val) => {
        console.log(val)
        const response = await SVLS_API.get('/catalog/v2/sports-feed/sports/events:search',
            {
                headers: {
                    Authorization: sessionStorage.getItem('jwt_token'),
                },
                params: {
                    providerId: PROVIDER_ID,
                    query: val,
                },
            }
        );
        if (response.status == 200) {
            setEventsData(response?.data)
        }
    }

    useEffect(() => {
        if (eventName) searchEvent(eventName)
    }, [eventName])

    const getCompetitionSlug = (competitionName: string) => {
        return competitionName
            .toLocaleLowerCase()
            .replace(/[^a-z0-9]/g, ' ')
            .replace(/ +/g, ' ')
            .trim()
            .split(' ')
            .join('-');
    };

    return (
        <div className="bets-menu">
            <IonCard className="bets-menu-card web-view">
                <IonCardContent className="bets-menu-card-content">

                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="EventsTypes Menu"
                        className="eventTypes-menu-tabs"
                        scrollButtons="off"
                    >
                        

                        <div className="filters-heading">Filters</div>

                        <div className={showList === "betStatus" ? "active eventTypes-menu-nav-link": "eventTypes-menu-nav-link"}>
                            <Button
                                className={"competitions-menu-tab"}
                                onClick={() => setShowList(showList === 'betStatus' ? '' : 'betStatus')}
                            // key={'bet-status-tab'}
                            >
                                <div>Transaction Type</div>
                                <img className="expand-icon" src={ExpandIcon} />
                            </Button>
                        </div>
                        {
                            showList == "betStatus" ?
                                <>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={showList}
                                        onChange={() => setShowList('betStatus')}
                                        aria-label="Competitions Menu"
                                        className="competitions-menu-tabs"
                                        scrollButtons="off"
                                    >
                                        {
                                            transactionTypes?.map((type, idx) => (
                                                <Tab
                                                    className={transactionType === type?.value ? "active competitions-menu-tab": "competitions-menu-tab"}
                                                    label={type?.name}
                                                    value={type?.value}
                                                    onClick={() => setTransactionType(type?.value)}
                                                    key={`${idx}-tab`}
                                                />
                                            ))
                                        }
                                    </Tabs>
                                </>
                                : null
                        }

                        <div className="myb-date-div">
                            <div className="myb-date-label">From</div>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disableFuture
                                    className="date-control"
                                    InputProps={{
                                        disableUnderline: true,
                                        readOnly: true,
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    format="DD/MM/yyyy"
                                    margin="normal"
                                    minDate={moment().subtract(1, 'months').calendar()}
                                    maxDate={dateTo}
                                    id="date-picker-inline"
                                    value={dateFrom ? dateFrom : null}
                                    onChange={(e) => {
                                        if (e == null) {
                                            setLoading(true);
                                            setDateFrom(null);
                                            setPageToken(['']);
                                        } else {
                                            setLoading(true);
                                            setDateFrom(e);
                                            setPageToken(['']);
                                        }
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className="myb-date-div">
                            <div className="myb-date-label">To</div>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disableFuture
                                    className="date-control"
                                    InputProps={{
                                        disableUnderline: true,
                                        readOnly: true,
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    format="DD/MM/yyyy"
                                    margin="normal"
                                    minDate={dateFrom} 
                                    id="date-picker-inline"
                                    value={dateTo ? dateTo : null}
                                    disabled={dateFrom ? false : true}
                                    onChange={(e) => {
                                        if (e == null) {
                                            setLoading(true);
                                            setDateTo(null);
                                            setPageToken(['']);
                                        } else {
                                            setLoading(true);
                                            setDateTo(e);
                                            setPageToken(['']);
                                        }
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        {/* <NavLink
              className="eventTypes-menu-nav-link"
              to="/exchange_sports/binary"
            >
              <Tab
                className="eventTypes-menu-tab"
                label="Binary"
                value={11010011}
              />
            </NavLink> */}
                    </Tabs>
                </IonCardContent>
            </IonCard>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        eventTypes: state.exchangeSports?.eventTypes,
        competitions: getCompetitionsByEventType(
            state.exchangeSports?.competitions,
            state.exchangeSports?.selectedEventType.id
        ),
        selectedEventType: state.exchangeSports?.selectedEventType,
        selectedCompetition: state.exchangeSports?.selectedCompetition,
        allowedConfig: state.common.allowedConfig,
        loggedIn: state.auth.loggedIn,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        fetchCompetitions: (eventTypeId: string) =>
            dispatch(fetchCompetitions(eventTypeId)),
        setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
        setCompetition: (competition: SelectedObj) =>
            dispatch(setCompetition(competition)),
        fetchEventsByCompetition: (eventTypeId: string, competitionId: string) =>
            dispatch(fetchEventsByCompetition(eventTypeId, competitionId, [])),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBetSidebar);
