import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IonImg } from '@ionic/react';
import { AppBar, Backdrop, Button, Divider, Menu, MenuItem, Toolbar } from '@material-ui/core';
import Modal from '../../components/Modal/Modal';

import './Header.scss';
import { RootState } from '../../models/RootState';
import logo from "../../assets/images/brand/title.png";
import MobileSideNav from './MobileSideNav';
import {
  toggleDarkMode,
  setSearchGame,
  handleSearchGame,
  fetchBalance,
  logout,
  getCurrencyTypeFromToken,
  setOpenWithdrawModal,
  setOpenDepositModal,
} from '../../store';

import User from "../../assets/images/home/homeview/user.png"
import Notifications from "../../assets/images/home/homeview/Notifications.svg"
import Balance from "../../assets/images/home/homeview/Balance.svg"
import { ReactComponent as DownArrow } from "../../assets/images/home/homeview/downArrow.svg"
import { ReactComponent as Terms } from "../../assets/images/home/homeview/terms.svg"
import { ReactComponent as GameRule } from "../../assets/images/home/homeview/gameRule.svg"
import { ReactComponent as PlayHistory } from "../../assets/images/home/homeview/history.svg";
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import LoginModal from '../Modal/LoginModal';
import LoginPage from '../LoginForm/LoginPage';
import ChangePwdForm from '../../components/ChangePassword/ChangePassword';
import NotificationPage from '../Home/Notification';
import { ShowSelfRagistration, ShowWhatsopIcon } from '../../constants/WhitelabelConfig';
import { BRAND_DOMAIN, BRAND_NAME } from '../../constants/Branding';
import SVLS_API from '../../svls-api';
import WhatsupImg from '../../assets/images/header/whatsup-flot.svg';
import AGPAY_API from '../../api-services/agpay-api';
import editProfile from '../../assets/images/home/homeview/edit.svg';
import { demoUserPrefix, isNotDemoUser } from '../../util/stringUtil';
import { setScroll } from '../../store/common/commonActions';

type StoreProps = {
  balance: number;
  bonus: number;
  exposure: number;
  fetchBalance: Function;
  loggedIn: boolean;
  setSearchGame: Function;
  searchGame: string;
  handleSearchGame: () => void;
  toggleDarkMode: (val: string) => void;
  prefersDark: string;
  openDepositModal: boolean;
  openWithdrawModal: boolean;
  setOpenDepositModal: Function;
  setOpenWithdrawModal: Function;
  logout(): void;
  scroll: boolean;
  setScrollDispatch: Function;
};

const MobileHeader: React.FC<StoreProps> = (props) => {
  const history = useHistory();
  const {
    fetchBalance,
    loggedIn,
    setSearchGame,
    searchGame,
    handleSearchGame,
    balance,
    bonus,
    prefersDark,
    toggleDarkMode,
    openDepositModal,
    openWithdrawModal,
    setOpenDepositModal,
    setOpenWithdrawModal,
    logout,
    exposure,
    scroll,
    setScrollDispatch
  } = props;

  const [tabValue, setTabValue] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const [amdinWhatsAppNumber, setAmdinWhatsAppNumber] = useState<string>();
  const [showDeposit, setShowDeposit] = useState<boolean>(true);
  const [showWithdrawal, setShowWithdrawal] = useState<boolean>(true);

  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loggedInUsername = sessionStorage
    .getItem('username')
    ?.startsWith(demoUserPrefix)
    ? 'Demo User'
    : sessionStorage.getItem('username');

  const loginHandler = () => {
    setModalOpen(true);
    setShowPopover({ open: false, event: undefined });
  };

  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  // const getPaymentProviders = async () => {
  //   const accountId = sessionStorage.getItem('aid');
  //   try {
  //     const response = await AGPAY_API.get(
  //       `/agpay/v2/payment-settings/accounts/${accountId}`,
  //       {
  //         headers: {
  //           Authorization: sessionStorage.getItem('jwt_token'),
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       if (response?.data?.withdraw_provider_list && response?.data?.withdraw_provider_list?.length > 0) {
  //         setShowWithdrawal(true);
  //       } else {
  //         setShowWithdrawal(false);
  //       }

  //       if (response?.data?.deposit_provider_list && response?.data?.deposit_provider_list?.length > 0) {
  //         setShowDeposit(true);
  //       } else {
  //         setShowDeposit(false);
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const setThemeHandler = () => {
    if (prefersDark === 'light') {
      localStorage.setItem('userTheme', 'dark');
      toggleDarkMode('dark');
    } else {
      localStorage.setItem('userTheme', 'light');
      toggleDarkMode('light');
    }
  };

  useEffect(() => {
    getAdminWhatsAppNumber();
  }, []);

  const getAdminWhatsAppNumber = async () => {
    try {
      const response = await SVLS_API.get(`/account/v2/books/${BRAND_DOMAIN}/sdm-contact`, {});
      if (response.status === 200) {
        setAmdinWhatsAppNumber(response?.data?.details);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (loggedIn) {
      fetchBalance();
    }
  }, [fetchBalance, loggedIn]);

  return (
    <>
      <AppBar position="static" className={'main-header-ctn'}>
        <Toolbar className="toolbar" variant="dense">
          <div className="header-actions">
            {loggedIn ? (
              <>
                <div className="header-left-actions">
                  <img src={logo} className='logo-mob-view' onClick={() => {
                    history.push('/home');
                    setScrollDispatch(!scroll);
                    }} height={24} />
                </div>
                <div className="header-right-actions">
                  <div className="theme-toggle-mobile">
                    {/* <div className="mob-theme-text">Theme</div> */}
                    <Button onClick={() => setThemeHandler()} className="mob-theme-toggle"></Button>
                  </div>
                  {ShowWhatsopIcon[BRAND_NAME.toLocaleLowerCase()] ? (
                    <Button className="actions-btn whatsapp-btn">
                      <div className="btn-content">
                        <a
                          href={`http://api.whatsapp.com/send?phone=${amdinWhatsAppNumber}`}
                          target="_blank"
                        >
                          <img src={WhatsupImg} className="social-icon1 mbl-view" />
                        </a>
                      </div>
                    </Button>
                  ) : null}
                  {/* <Button className='notifications-img'><img height={19.5} width={20} src={Notifications} /></Button> */}
                  {!ShowSelfRagistration[BRAND_NAME.toLocaleLowerCase()] ? (
                    <Button className="signup-btn" size="small">
                      <img src={Balance} style={{ paddingRight: '7.5px' }} />{' '}
                      <div className='bal-container'>
                        <div>
                          Bal:{' '}
                          {balance !== null
                            ? Number(balance / cFactor).toFixed(2)
                            : '-.--'}
                        </div>
                        {/* <div>
                          Exp:{' '}
                          {exposure !== null
                            ? Number(exposure / cFactor).toFixed(2)
                            : '-.--'}
                        </div> */}
                      </div>
                    </Button>
                  ) : null
                  }

                  {ShowSelfRagistration[BRAND_NAME.toLocaleLowerCase()] ? (
                    <>
                      {
                        showDeposit ?
                          <Button className='signup-btn mob-view' onClick={() => history.push('/transaction/deposit')}>D</Button>
                          : null
                      }
                      {
                        showWithdrawal ?
                          <Button className='signup-btn mob-view' onClick={() => history.push('/transaction/withdraw')}>W</Button>
                          : null
                      }
                    </>
                  ) : null}
                  <div className='mob-username'>
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="contained"
                      className='login-btn p-0 mob-view'
                      disableElevation
                      onClick={handleClick}
                    // endIcon={<KeyboardArrowDownIcon />}
                    >
                      {/* <img height={28} src={User} style={{ borderRadius: "4px" }} /> */}
                      <div className='username-text'>{sessionStorage
                            .getItem('username')
                            ?.startsWith(demoUserPrefix)
                            ? 'Demo User'
                            : sessionStorage.getItem('username')}
                      <div className='username-text'>Bal:{' '}
                          {balance !== null
                            ? Number(balance / cFactor).toFixed(2)
                            : '-.--'}
                        </div>
                          <div className='username-text'>
                            Bonus:{' '}
                            {bonus !== null
                              ? Number(bonus / cFactor).toFixed(2)
                              : '-.--'}
                          </div>
                        </div>
                      <DownArrow className='downArrow' />

                    </Button>
                  </div>
                  <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {/* <MenuItem className='menu-item-text' onClick={handleClose} disableRipple>
                      <PlayHistory />
                      Play History
                    </MenuItem>
                    <div className="menu-border-bottom"></div> */}

                    {isNotDemoUser ? 
                    <><MenuItem className='menu-item-text' onClick={() => {
                      history.push('/profile');
                      handleClose();
                    }} disableRipple>
                      <div slot="start">
                      <div className="profile-link">{loggedInUsername}</div>
                      <div className="balance">
                        Edit Profile
                        <img src={editProfile} className="edit-profile" />
                      </div>
                    </div>
                    </MenuItem>
                    <div className="menu-border-bottom"></div></> : null}
                    <MenuItem className='menu-item-text' onClick={() => {
                      history.push('/responsible-gambling');
                      handleClose();
                    }} disableRipple>
                      <GameRule />
                      Game Rules
                    </MenuItem>
                    <div className="menu-border-bottom"></div>

                    <MenuItem className='menu-item-text' onClick={() => {
                      history.push('/account/my_bets');
                      handleClose();
                    }} disableRipple>
                      <Terms />
                      My Bets
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem className='menu-item-text' onClick={() => {
                      history.push('/account/my_wallet');
                      handleClose();
                    }} disableRipple>
                      <Terms />
                      My Wallet
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem className='menu-item-text' onClick={() => {
                      history.push('/account/account_statement');
                      handleClose();
                    }} disableRipple>
                      <Terms />
                      Acc Statement
                    </MenuItem>

                    {ShowSelfRagistration[BRAND_NAME.toLocaleLowerCase()] ? (
                      <>
                        <div className="menu-border-bottom"></div>
                        <MenuItem className='menu-item-text' onClick={() => {
                          history.push('/my_transactions');
                          handleClose();
                        }} disableRipple>
                          <Terms />
                          My Transactions
                        </MenuItem>
                        <div className="menu-border-bottom mob-view"></div>
                        <MenuItem className='menu-item-text mob-view' onClick={() => {
                          history.push('/transaction/deposit');
                          handleClose();
                        }} disableRipple>
                          <Terms />
                          Deposit
                        </MenuItem>
                        <div className="menu-border-bottom mob-view"></div>
                        <MenuItem className='menu-item-text mob-view' onClick={() => {
                          history.push('/transaction/withdraw');
                          handleClose();
                        }} disableRipple>
                          <Terms />
                          Withdraw
                        </MenuItem>
                      </>
                    ) : null}
                    {isNotDemoUser() ? 
                      <>
                      <div className="menu-border-bottom"></div>
                      <MenuItem className='menu-item-text' 
                      onClick={() => {
                        history.push('/referral');
                        handleClose();
                      }} disableRipple>
                        <Terms />
                        Referral
                      </MenuItem></> : null  
                    }
                    <div className="menu-border-bottom"></div>
                    <MenuItem className='menu-item-text' onClick={() => {
                      history.push('/account/pl_statement');
                      handleClose();
                    }} disableRipple>
                      <Terms />
                      PL Statement
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem className='menu-item-text' onClick={() => {
                      history.push('/bonus_statement');
                      handleClose();
                    }} disableRipple>
                      <Terms />
                      Bonus Statement
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem className='menu-item-text' onClick={() => {
                      history.push('/bonus/turnover_history');
                      handleClose();
                    }} disableRipple>
                      <Terms />
                      Turnover History
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem className='menu-item-text' onClick={() => {
                      history.push('/terms-conditions');
                      handleClose();
                    }} disableRipple>
                      <Terms />
                      T&C
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <Button onClick={() => { logout() }} className="logout-btn">Logout</Button>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <div className="header-left-actions">
                  <img src={logo} className='logo-mob-view' onClick={() => {
                    history.push('/home');
                    setScrollDispatch(!scroll);
                    }} height={24} />
                </div>
                <div className="header-right-actions">
                  <div className="theme-toggle-mobile">
                    <div className="mob-theme-text">Theme</div>
                    <Button onClick={() => setThemeHandler()} className="mob-theme-toggle"></Button>
                  </div>
                  {ShowWhatsopIcon[BRAND_NAME.toLocaleLowerCase()] ? (
                    <Button className="actions-btn whatsapp-btn">
                      <div className="btn-content">
                        <a
                          href={`http://api.whatsapp.com/send?phone=${amdinWhatsAppNumber}`}
                          target="_blank"
                        >
                          <img src={WhatsupImg} className="social-icon1" />
                        </a>
                      </div>
                    </Button>
                  ) : null}
                  <Button
                    className="login-btn"
                    onClick={() => {
                      loginHandler();
                      setTabValue(0);
                    }}
                    size="small"
                  >
                    Login
                  </Button>
                  {/* <Button
                    className="signup-btn"
                    onClick={() => {
                      loginHandler();
                      setTabValue(1);
                    }}
                    size="small"
                  >
                    Sign Up
                  </Button> */}
                </div>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>

      <Backdrop className="backdrop-ctn" open={modalOpen}>
        <LoginModal
          open={modalOpen}
          closeHandler={modalCloseHandler}
          noTitle={true}
          size="md"
          customClass={'login-modal-ctn'}
        >
          <LoginPage
            modalCloseHandler={modalCloseHandler}
            tabActive={tabValue}
          ></LoginPage>
        </LoginModal>
      </Backdrop>

      <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
        <Modal
          open={changePwdMOdal}
          closeHandler={() => setChangePwdMOdal(false)}
          title="Change Password"
          size="xs"
        >
          <ChangePwdForm
            closeHandler={() => setChangePwdMOdal(false)}
            backHandler={() => { }}
          />
        </Modal>
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allowedConfig: state.common.allowedConfig,
    scroll: state.common.scroll,
    loggedIn: state.auth.loggedIn,
    exposure: state.auth.balanceSummary.exposure,
    balance: state.auth.balanceSummary.balance,
    bonus: state.auth.balanceSummary.bonus,
    searchGame: state.indianCasino.searchGame,
    prefersDark: state.common.prefersDark,
    openDepositModal: state.auth.openDepositModal,
    openWithdrawModal: state.auth.openWithdrawModal,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    logout: () => dispatch(logout()),
    setSearchGame: (data: string) => dispatch(setSearchGame(data)),
    handleSearchGame: () => dispatch(handleSearchGame()),
    toggleDarkMode: (val: string) => dispatch(toggleDarkMode(val)),
    setOpenDepositModal: (val) => dispatch(setOpenDepositModal(val)),
    setOpenWithdrawModal: (val) => dispatch(setOpenWithdrawModal(val)),
    setScrollDispatch: (data: boolean) => dispatch(setScroll(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
function alpha(white: any, arg1: number) {
  throw new Error('Function not implemented.');
}
