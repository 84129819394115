import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../models/RootState';
import './MlobbyIframeNew.scss';
import { isMobile, isIOS } from 'react-device-detect';
import { enableMlobby } from '../../../store/auth/authActions';
import CasinoImg from '../../../assets/images/casino/casino-chip.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import SVLS_API from '../../../svls-api';
import { Backdrop } from '@material-ui/core';
import LoginModal from '../../../components/Modal/LoginModal';
import LoginPage from '../../../components/LoginForm/LoginPage';
import MiniGameBtn from "../../../assets/images/casino/mini_games_btn.png";
import MiniGameGif from "../../../assets/images/casino/mini_games_gif.gif";

type StoreProps = {
  gameUrl: string;
  loggedIn: boolean;
  isMolobby: boolean;
  enableMlobby: Function
};

type RouteParams = {
  gamePath: string;
};

const MlobbyIframeNew: React.FC<StoreProps> = (props) => {
  const { isMolobby, enableMlobby } = props;
  const [gameSrc, setGameSrc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);

  const { loggedIn } = props;

  const { gamePath } = useParams<RouteParams>();
  const history = useHistory();
  const ref = useRef(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (isMolobby) {
      if (event.key === "Escape") {
        if (isMolobby) {
          sessionStorage.removeItem('mlobby')
          enableMlobby(false)
        } else {
          enableMlobby(true);
          sessionStorage.setItem('mlobby', 'true');
        }
      }
    }
  };

  const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
          // sessionStorage.removeItem('mlobby');
          // document.getElementsByClassName('mlobby-main-dc')[0].classList.a
          ref.current.classList.add('d-none');
          document.getElementsByClassName('mini-casino-btn')[0].classList.remove('d-none')

          // enableMlobby(false)
          // enableMlobby(true);
          sessionStorage.setItem('mlobby', 'true');
      }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isMolobby) {
        //document.addEventListener("keydown", handleHideDropdown, true);
        document.addEventListener("click", handleClickOutside, true);
        return () => {
          //document.removeEventListener("keydown", handleHideDropdown, true);
          document.removeEventListener("click", handleClickOutside, true);
        };
      }
    }, 10);
  }, [isMolobby]);

  const getGameUrl = async (
    gameId: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedIn) {
      setLoading(true);
      const claims = sessionStorage.getItem('jwt_token').split('.')[1];
      const userStatus = JSON.parse(window.atob(claims)).status;

      if (userStatus === 0 || userStatus === 3) {
        return history.push(`/home`);
      }

      let response;
      try {
        const reqBody = {
          gameId: gameId,
          providerName: provider,
          subProviderName: subProvider,
          platformId: isMobile ? 'mobile' : 'desktop',
          type: "MINI"
        };
        response = await SVLS_API.post('/catalog/v2/live-casino/game-url', reqBody, {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        });
      } catch (e) {
        console.log(e);
      }

      if (response?.data) {
        setGameSrc(response.data);
        setLoading(false);
      }
    } else {
      history.push(`/`);
    }
  };

  useEffect(() => {
    const gameId = '902000';
    const gameCode = 'YG-MLOBBY';
    const provider = 'RG';
    const subProvider = 'Yuvi Games Lobby';
    if (loggedIn) {
      getGameUrl(gameId, gameCode, provider, subProvider);
    }
  }, [loggedIn]);

  const loginHandler = () => {
    setModalOpen(true);
  };

  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  const onMlobby = async (
  ) => {
    if (loggedIn) {
      document.getElementsByClassName('mlobby-main-dc')[0].classList.remove('d-none');
      document.getElementsByClassName('mini-casino-btn')[0].classList.add('d-none')

      if (!isMolobby) {
        sessionStorage.setItem('mlobby', 'true');
      }

    } else {
      loginHandler();
    }
  };
  const setDialogShow = (show: boolean) => {
    setShowDialog(show);
  };

  return (
    <>
      <div ref={ref} className='mlobby-main-dc d-none'>
        <div className="mini-iframe-close-btn" onClick={() => {
          ref.current.classList.add('d-none');
          document.getElementsByClassName('mini-casino-btn')[0].classList.remove('d-none')
        }}>
          <KeyboardArrowDownRounded />
        </div>
        <div className="dc-iframe-ctn-mlobby">
          <iframe
            src={gameSrc}
            title="DC game"
            allowFullScreen
            sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
          ></iframe>
        </div>
      </div>

      <a onClick={() => onMlobby()} className="exch-mob-nav-link mlobby-main mini-casino-btn">
        <div className="exch-nav-item-ctn ">
          {/* <img className="mini-game-btn-bg" src={MiniGameBtn} /> */}
          {/* <img src={MiniGameGif} className="mini-games-gif" /> */}
        </div>
      </a>
      <Dialog
        open={showDialog}
        onClose={() => setDialogShow(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Notice</DialogTitle>
        <DialogContent>
          <div className="dc-dialog-body">You have to be logged in to play</div>
        </DialogContent>
        <DialogActions className="dc-dialog-actions">
          <Button
            onClick={() => setDialogShow(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className="backdrop-ctn" open={modalOpen}>
        <LoginModal
          open={modalOpen}
          closeHandler={modalCloseHandler}
          noTitle={true}
          size="md"
          customClass={'login-modal-ctn'}
        >
          <LoginPage
            modalCloseHandler={modalCloseHandler}
            tabActive={tabValue}
          ></LoginPage>
        </LoginModal>
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gameUrl: state.common.dcGameUrl,
    loggedIn: state.auth.loggedIn,
    isMolobby: state.auth.isMolobby
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    enableMlobby: (isMolobby) => dispatch(enableMlobby(isMolobby)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MlobbyIframeNew);
