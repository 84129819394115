import React from 'react';
import './CasinoNew.scss';

type StoreProps = {
    game: any;
  };
const GetMinAndMaxValues: React.FC<StoreProps> = (props) => {
    const {game} = props;
  return (
    <>
      <div className="price-overlay web-view">
        <span className="min-price-overlay">
          {(game?.min_stake ? game.min_stake : 0 )+ ' - '}
        </span>
        <span className="max-price-overlay">
          {game?.max_stake ? game.max_stake : 0}
        </span>
      </div>
      <div className="price-overlay mob-view">
        <span className="min-price-overlay">
          {(game?.min_stake ? game.min_stake : 0) + ' - '}
        </span>
        <span className="max-price-overlay-mob-view">
          {game?.max_stake ? game.max_stake : 0}
        </span>
      </div>
    </>
  );
};

export default GetMinAndMaxValues;