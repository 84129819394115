import React, { useState, useCallback, useEffect } from 'react';
import {
  IonCardHeader,
  IonRow,
  IonCol,
  IonCardContent,
  IonLabel,
  IonInput,
  IonCard,
  IonLoading,
  IonTitle,
  IonSpinner,
  IonToast,
  IonButton,
} from '@ionic/react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { NavLink } from 'react-router-dom';
import './MyProfile.scss';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '../Modal/Modal';
import ChangePwdForm from '../ChangePassword/ChangePassword';
import MfaForm from '../MfaForm/MfaForm';
import TabPanel from '../TabPanel/TabPanel';
import { CloseOutlined } from '@material-ui/icons';
import { Drawer } from '@material-ui/core';
import { useHistory } from 'react-router';
import SVLS_API from '../../svls-api';
import ReportsSideBar from '../ReportsSideBar/ReportsSideBar';

type ToastProps = {
  message: string;
  color: string;
  show: boolean;
};

type UpdateUserPersonalDetailsrequest = {
  fullName: string;
  phoneNumber: string;
  emailId: string;
  address: string;
  city: string;
  pinCode: string;
};

type UserPersonalDetails = UpdateUserPersonalDetailsrequest & {
  username: string;
};

const MyProfile: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserPersonalDetails>();
  const [toastDetails, setToastDetails] = useState<ToastProps>({
    message: '',
    color: '',
    show: false,
  });
  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const [open2FAModal, setOpen2FAModal] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(false);
  const history = useHistory();
  const [err, setErr] = useState<string>();

  const fetchData = async () => {
    setLoading(true);
    try {
      const userName = sessionStorage.getItem('username');
      const response = await SVLS_API.get(
        `/account/v2/users/${userName}/profile`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );

      setUserDetails(response.data);
    } catch (err) {
      setErr(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const pinCodeRegex = /^[1-9][0-9]{5}$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userDetails ? userDetails.fullName : '',
      phoneNumber: userDetails ? userDetails.phoneNumber : '',
      emailId: userDetails ? userDetails.emailId : '',
      address: userDetails ? userDetails.address : '',
      city: userDetails ? userDetails.city : '',
      pinCode: userDetails ? userDetails.pinCode : '',
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        'Phone number is not valid'
      ),
      pinCode: Yup.string().matches(pinCodeRegex, 'Pin Code is not valid'),
    }),
    onSubmit: (values) => {
      let data: UpdateUserPersonalDetailsrequest = {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        emailId: values.emailId,
        address: values.address,
        city: values.city,
        pinCode: values.pinCode,
      };
      updateDetails(data);
    },
  });

  const updateDetails = async (data: UpdateUserPersonalDetailsrequest) => {
    try {
      setErr('');
      setProgress(true);
      const userName = sessionStorage.getItem('username');
      const response = await SVLS_API.put(
        `/account/v2/users/${userName}/profile`,
        data,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 204) {
        setToastDetails({
          message: 'Details saved successfully',
          color: 'success',
          show: true,
        });
      } else {
        setToastDetails({
          message: 'Unable to save details, something went wrong!',
          color: 'danger',
          show: true,
        });
      }
      fetchData();
    } catch (err) {
      setErr(err?.response?.data?.message);
      setToastDetails({
        message: 'Unable to save details, something went wrong!',
        color: 'danger',
        show: true,
      });
    } finally {
      setProgress(false);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOnBack = () => {
    history.goBack();
  };
  return (
    <>
      <IonToast
        isOpen={toastDetails.show}
        onDidDismiss={() =>
          setToastDetails({
            message: '',
            color: '',
            show: false,
          })
        }
        message={toastDetails.message}
        color={toastDetails.color}
        position="top"
        duration={1000}
      />
      <IonRow>
        <IonCol sizeMd="1.7" sizeLg="1.7" className="web-view">
          <div className="compt-menu-section">
            <div className="sticky-col">
              <ReportsSideBar />
            </div>
          </div>
        </IonCol>
        <IonCol>
          <IonCard className="my-profile-ctn">
            {/* <IonCardHeader className="my-profile-ctn-header">
              <IonTitle className="page-title">
                <span onClick={handleOnBack} className="back-arrow">
                  <ArrowBackIcon />
                </span>
                My Profile
              </IonTitle>
            </IonCardHeader> */}
            <IonCardContent className="my-profile-user-details-ctn">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Personal Information"
                  style={{ textTransform: 'capitalize' }}
                />
                <Tab
                  label="Security Settings"
                  style={{ textTransform: 'capitalize' }}
                />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <div className="personal-tab-ctn">
                  <div className="personal-tab-inner">
                    {loading ? (
                      <IonLoading
                        isOpen={loading}
                        message={'Fetching user details...'}
                      />
                    ) : (
                      <form onSubmit={formik.handleSubmit}>
                        {/* <IonRow className="user-details">
                          <IonCol>
                            <IonLabel className="username-label">
                              Username:
                            </IonLabel>
                          </IonCol>
                          <IonCol>
                            <IonLabel className="username">
                              {userDetails ? userDetails.username : null}
                            </IonLabel>
                          </IonCol>
                        </IonRow> */}
                        {/* <div className="content-header">Personal Information</div> */}
                        <IonRow className="profile-form-align">
                          <IonCol className="profile-col-align">
                            <IonInput
                              id="user-adornment-FullName"
                              type="text"
                              name="fullName"
                              placeholder="Full Name"
                              value={formik.values.fullName}
                              onInput={(e) => formik.handleChange(e)}
                              className="input-design"
                            />
                            {formik.touched.fullName &&
                            formik.errors.fullName ? (
                              <FormHelperText
                                id="my-helper-text"
                                className="my-helper-text"
                              >
                                {formik.errors.fullName}
                              </FormHelperText>
                            ) : null}
                          </IonCol>
                          <IonCol className="profile-col-align">
                            <IonInput
                              id="user-adornment-emailId"
                              type="email"
                              name="emailId"
                              value={formik.values.emailId}
                              placeholder="Email"
                              onInput={(e) => formik.handleChange(e)}
                              className="input-design"
                            />
                            {formik.touched.emailId && formik.errors.emailId ? (
                              <FormHelperText
                                id="my-helper-text"
                                className="my-helper-text"
                              >
                                {formik.errors.emailId}
                              </FormHelperText>
                            ) : null}
                          </IonCol>
                          <IonCol className="profile-col-align">
                            <IonInput
                              id="user-adornment-phone"
                              type="tel"
                              inputMode="tel"
                              name="phoneNumber"
                              placeholder="Phone Number"
                              value={formik.values.phoneNumber}
                              onInput={(e) => formik.handleChange(e)}
                              className="input-design"
                            />
                            {formik.touched.phoneNumber &&
                            formik.errors.phoneNumber ? (
                              <FormHelperText
                                id="my-helper-text"
                                className="my-helper-text"
                              >
                                {formik.errors.phoneNumber}
                              </FormHelperText>
                            ) : null}
                          </IonCol>
                          <IonCol className="profile-col-align">
                            <IonInput
                              name="address"
                              value={formik.values.address}
                              onInput={(e) => formik.handleChange(e)}
                              className="input-design"
                              placeholder="Address"
                            ></IonInput>
                            {formik.touched.address && formik.errors.address ? (
                              <FormHelperText
                                id="my-helper-text"
                                className="my-helper-text"
                              >
                                {formik.errors.address}
                              </FormHelperText>
                            ) : null}
                          </IonCol>
                          <IonCol className="profile-col-align">
                            <IonInput
                              name="city"
                              value={formik.values.city}
                              placeholder="City"
                              onInput={(e) => formik.handleChange(e)}
                              className="input-design"
                            ></IonInput>
                            {formik.touched.city && formik.errors.city ? (
                              <FormHelperText
                                id="my-helper-text"
                                className="my-helper-text"
                              >
                                {formik.errors.city}
                              </FormHelperText>
                            ) : null}
                          </IonCol>
                          <IonCol className="profile-col-align">
                            <IonInput
                              className="input-design"
                              name="pinCode"
                              value={formik.values.pinCode}
                              placeholder="Pincode"
                              onInput={(e) => formik.handleChange(e)}
                            ></IonInput>
                            {formik.touched.pinCode && formik.errors.pinCode ? (
                              <FormHelperText
                                id="my-helper-text"
                                className="my-helper-text"
                              >
                                {formik.errors.pinCode}
                              </FormHelperText>
                            ) : null}
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          {/* <IonCol className="label-col">
                          <IonLabel>Pincode</IonLabel>
                        </IonCol> */}
                        </IonRow>
                        <IonRow className="err-msg">{!!err && err}</IonRow>
                        <IonRow className="my-profile-ctn-footer">
                          <Button
                            className="submit-btn"
                            color="primary"
                            type="submit"
                            endIcon={
                              progress ? (
                                <IonSpinner name="lines-small" />
                              ) : null
                            }
                            variant="contained"
                          >
                            Save
                          </Button>
                        </IonRow>
                      </form>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div className="security-tab-ctn">
                  <IonRow className="security-row">
                    {/* <IonLabel>Password</IonLabel> */}
                    <IonButton
                    className="change-pwd-btn web-view"
                    fill="solid"
                    onClick={() => setChangePwdMOdal(true)}
                  >
                    <span className="btn-text">Change password</span>
                  </IonButton>
                  <IonButton
                    className="change-pwd-btn mob-view"
                    fill="solid"
                    onClick={() => setChangePwdMOdal(true)}
                  >
                    <span className="btn-text">Change</span>
                  </IonButton>
                </IonRow>
              </div>
            </TabPanel>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>

      <Backdrop className="backdrop-ctn" open={open2FAModal}>
        <Modal
          open={open2FAModal}
          closeHandler={() => setOpen2FAModal(false)}
          title="Set up MFA"
          size="sm"
        >
          <MfaForm
            closeMfaFormModal={() => setOpen2FAModal(false)}
            mfaStatusChangeHandler={(status) => setMfaEnabled(status)}
          ></MfaForm>
        </Modal>
      </Backdrop>
      <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
        <Drawer
          anchor={'right'}
          open={changePwdMOdal}
          onClose={() => setChangePwdMOdal(true)}
          className="light-bg-title game-rules-drawer"
          title="Change Password"
          // size="md"
        >
          <div className="game-rules-header">
            <div className="game-rules-title">Change Password</div>
            <div
              className="game-rules-close cursor"
              onClick={() => setChangePwdMOdal(false)}
            >
              <CloseOutlined />
            </div>
          </div>
          <ChangePwdForm
            closeHandler={() => setChangePwdMOdal(false)}
            backHandler={() => {}}
          />
        </Drawer>
        {/* <Modal
          open={changePwdMOdal}
          closeHandler={() => setChangePwdMOdal(false)}
          title="Change Password"
          size="xs"
        >
          <ChangePwdForm
            closeHandler={() => setChangePwdMOdal(false)}
            backHandler={() => { }}
          />
        </Modal> */}
      </Backdrop>
    </>
  );
};

export default MyProfile;
