import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonCol, IonRow } from '@ionic/react';
import { RootState } from '../../models/RootState';
import withdrawal from '../../assets/images/common/icons/withdrawal.png';
import gpay from '../../assets/images/common/Transactions/gpay.svg';
import bankIcon from '../../assets/images/common/Transactions/bank.svg';
import paytm from '../../assets/images/common/Transactions/paytm.svg';
import phonepay from '../../assets/images/common/Transactions/phonepe.svg';
import bank from '../../assets/images/common/Transactions/bank.svg';
import TabPanel from '../../components/TabPanel/TabPanel';
import { Tabs, Tab, Button, TextField, Dialog, Select, MenuItem } from '@material-ui/core';
import './Payment.scss';
import './Withdraw.scss';
import NumberFormat from 'react-number-format';
import Spinner from '../../components/Spinner/Spinner';
import { toast } from 'react-toastify';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { getCurrencyTypeFromToken, setOpenWithdrawModal } from '../../store';
import { AccountDetails } from './AccountDetails';
import Add from '@material-ui/icons/Add';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { IonSpinner } from '@ionic/react';
import { DeleteForeverOutlined } from '@material-ui/icons';
import AGPAY_API from '../../api-services/agpay-api';
import ReportsSideBar from '../../components/ReportsSideBar/ReportsSideBar';

type StoreProps = {
  setOpenWithdrawModal: Function;
};

const Withdrawal: React.FC<StoreProps> = (props) => {
  const { setOpenWithdrawModal } = props;
  const [tabValue, setTabValue] = useState<number>(0);
  const [showBankOptions, setShowBankOptions] = useState<boolean>(false);
  const [paymentOption, setPaymentOption] = useState<string>('');
  const [onlinePaymentOption, setOnlinePaymentOption] = useState<string>('');
  const [accountDetails, setAccountDetails] = useState<AccountDetails[]>();
  const [selectedAccountId, setSelectedAccountId] = useState<string>();
  const [selectedCryptoAccount, setSelectedCryptoAccount] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [addAccount, setAddAccount] = useState<boolean>(false);
  const [accountNumber, setAccountNumber] = useState<string>();
  const [withdrawAmount, setWithdrawAmount] = useState<string>();
  const [mobileNumber, setMobileNumber] = useState<string>();
  const [holderName, setHolderName] = useState<string>();
  const [displayName, setDisplayName] = useState<string>();
  const [ifscCode, setIfscCode] = useState<string>();
  const [bankName, setBankName] = useState<string>();
  const [branchName, setBranchName] = useState<string>();
  const [walletAddress, setWalletAddress] = useState<string>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [providersList, setProvidersList] = useState([]);
  const [currencyList, setCurrencyList] = useState<any>([]);
  const [selectedCrypto, setSelectedCrypto] = useState<any>({});

  const history = useHistory();

  const [withdrawNotes, setWithdrawNotes] = useState<string>();
  const [recievableAmount, setReceivableAmount] = useState<string>('');

  const getCurrencyList = async () => {
    try {
      const response = await AGPAY_API.get(
        `/agpay/v2/zenpay-crypto/currencies`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200) {
        setCurrencyList(response?.data?.crypto_currency);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPaymentProviders = async () => {
    setLoading(true);
    const accountId = sessionStorage.getItem('aid');
    try {
      const response = await AGPAY_API.get(
        `/agpay/v2/payment-settings/accounts/${accountId}`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        let providersList = response?.data?.withdraw_provider_list;
        setProvidersList(response?.data?.withdraw_provider_list);
        if (providersList?.length > 0) {
          if (providersList[0] === 'ABCMONEY') setTabValue(0);
          else if (providersList[0] === 'PGMAN') setTabValue(1);
          else if (providersList[0] === 'ZENPAY') setTabValue(2);
          else if (providersList[0] === 'ZENPAYCRYPTO') setTabValue(3);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPaymentProviders();
  }, [])

  useEffect(() => {
    if (providersList?.length === 1) {
      if (providersList[0] === "ABCMONEY") setTabValue(0);
      else if (providersList[0] === "PGMAN") setTabValue(1);
      else if (providersList[0] === "ZENPAY") setTabValue(2);
      else if (providersList[0] === "ZENPAYCRYPTO") setTabValue(3);
    }
  }, [providersList])

  const fetchPaymentMethod = async () => {
    try {
      const response = await AGPAY_API.get(`/agpay/v2/pgman/payment-methods`, {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
        params: {
          admin: false,
          paymentOption:
            paymentOption === 'GPAY' || paymentOption === 'PHONEPE'
              ? 'UPI'
              : paymentOption === 'CRYPTO_WALLET_TRANSFER' ? 'CRYPTO_WALLET'
              : paymentOption
        },
      });
      if (response.status === 200) {
        setAccountDetails(response?.data);
      } else {
      }
    } catch (error) { }
  };

  const submitOnlinePayment = async (e, selectedCurrency = 'abcmoney') => {
    e.preventDefault();
    try {
      const payload = {
        amount: Number(withdrawAmount),
        gatewayProvider: "INSTAPETECH",
        notes: withdrawNotes,
        payment_method_id: selectedAccountId,
        payment_option: onlinePaymentOption,
        currency_type: 'INR',
        mobile_number: mobileNumber
        // paymentMethod: accountDetails[0].paymentMethod,
      };
      setLoading(true);
      const response = await AGPAY_API.post(`/agpay/v2/${selectedCurrency}/transactions/:withdraw`, payload, {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      if (response.status === 204) {
        toast.success(
          <div>
            <SuccessIcon /> {'Transaction Saved Successfully!'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.error(
          <div>
            <ErrorIcon /> {'Something went wrong'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        <div>
          <ErrorIcon /> {error?.response?.data?.message}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setLoading(false);
    }
  }

  const submitPayment = async (e) => {
    e.preventDefault();
    if (Number(withdrawAmount) < 100) {
      toast.error(
        <div>
          <ErrorIcon /> {'Withdrawal Amount cannot be less than 100'}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return false;
    }
    setLoading(true);
    try {
      const payload = {
        amount: withdrawAmount,
        notes: withdrawNotes,
        payment_method_id: selectedAccountId,
        // paymentMethod: accountDetails[0].paymentMethod,
      };
      const response = await AGPAY_API.post(`/agpay/v2/pgman/transactions/:withdraw`, payload, {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      if (response.status === 204) {
        toast.success(
          <div>
            <SuccessIcon /> {'Transaction Saved Successfully!'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setOpenWithdrawModal(false);
        history.push('/my_transactions');
      } else {
        toast.error(
          <div>
            <ErrorIcon /> {'Something went wrong'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        <div>
          <ErrorIcon /> {error?.response?.data?.message}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setLoading(false);
    }
  };

  const getReceivableAmount = async () => {
    if (!withdrawAmount) {
      toast.error(
        <div>
          <ErrorIcon /> {'Enter Amount'}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return false;
    }
    try {
      const payload = {
        amount: +withdrawAmount,
      };
      const response = await AGPAY_API.post(
        `/agpay/v2/zenpay-crypto/exchange-rates`,
        payload,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200) {
        response.data?.data.map((currency) => {
          if (
            currency?.network_id == selectedCryptoAccount.paymentMethodDetails.networkId &&
            currency?.crypto_currency == selectedCryptoAccount.paymentMethodDetails?.cryptoCurrency
          ) {
            setReceivableAmount(currency?.receivable_amount);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (paymentOption) {
      fetchPaymentMethod();
      setAccountDetails([]);
      setSelectedAccountId('');
      setWithdrawAmount('');
      setWithdrawNotes('');
      setAccountNumber('');
      setWithdrawAmount('');
      setHolderName('');
      setDisplayName('');
      setIfscCode('');
      setAddAccount(false);
    }
  }, [paymentOption]);

  useEffect(() => {
    if (addAccount) {
      setSelectedAccountId('');
    }
  }, [addAccount]);

  useEffect(() => {
    if (selectedAccountId) {
      setAddAccount(false);
    }
  }, [selectedAccountId]);

  const submitDetails = async (e) => {
    e.preventDefault();
    console.log(e);
    setLoading(true);
    try {
      const payload = {
        accountHolderName: holderName,
        accountNumber: accountNumber,
        displayName: "",
        bankName: bankName,
        branchName: branchName,
        ifscCode: ifscCode,
        paymentMethod:
          paymentOption === 'NEFT' ? 'BANK_TRANSFER' : 'UPI_TRANSFER',
      };

      const payTMPayload = {
        accountNumber: accountNumber,
        accountHolderName: holderName,
        displayName: displayName,
        paymentMethod: "PAYTM_WALLET_TRANSFER"
      }

      const cryptoPayload = {
        paymentMethod: paymentOption,
        accountNumber: walletAddress,
        cryptoCurrencyType: selectedCrypto.crypto_currency,
        blockchain: selectedCrypto.blockchain,
        networkId: selectedCrypto.network_id
      }

      const response = await AGPAY_API.post(
        `/agpay/v2/pgman/payment-methods`,
        paymentOption === "PAYTM_WALLET" ? payTMPayload : paymentOption === 'CRYPTO_WALLET_TRANSFER' ? cryptoPayload : payload,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200 || 204) {
        toast.success(
          <div>
            <SuccessIcon /> {'Deatils Saved Successfully!'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        history.push('/my_transactions')
        setOpenWithdrawModal(false);
      } else {
        toast.error(
          <div>
            <ErrorIcon /> {'Something went wrong'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        <div>
          <ErrorIcon /> {error?.response?.data?.message}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setLoading(false);
    }
  };

  const deletePaymentMethod = async () => {
    try {
      const response = await AGPAY_API.delete(
        `/agpay/v2/pgman/payment-methods/${deleteId}`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );

      if (response.status === 204 || 200) {
        fetchPaymentMethod();
        toast.success(
          <div>
            <SuccessIcon /> {"Beneficiary account deleted successfully"}
          </div>
        );
      }
      // setDeleteId(null);
    } catch (err) {
      toast.error(err.response.data.message);
      // setDeleteId(null);
    }
  }
  const submitZenpayPayment = async (e) => {
    e.preventDefault();
    if (Number(withdrawAmount) < 100) {
      toast.error(
        <div>
          <ErrorIcon /> {'Withdrawal Amount cannot be less than 100'}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return false;
    }
    setLoading(true);
    try {
      const payload = {
        amount: withdrawAmount,
        notes: withdrawNotes,
        payment_method_id: selectedAccountId,
        payment_method: 'BANK_TRANSFER',
        currency_type: 'INR',
        mobile_number: '9000900099',
      };
      const response = await AGPAY_API.post(
        `/agpay/v2/zenpay/transactions/:withdraw`,
        payload,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200) {
        toast.success(
          <div>
            <SuccessIcon /> Transaction Saved Successfully!
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setOpenWithdrawModal(false);
        history.push('/my_transactions');
      } else {
        toast.error(
          <div>
            <ErrorIcon /> {'Something went wrong'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        <div>
          <ErrorIcon /> {error?.response?.data?.message}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setLoading(false);
    }
  };

  const submitZenpayCryptoPayment = async (e) => {
    e.preventDefault();
    if (Number(withdrawAmount) < 100) {
      toast.error(
        <div>
          <ErrorIcon /> {'Withdrawal Amount cannot be less than 100'}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return false;
    }
    setLoading(true);
    try {
      const payload = {
        payment_method: "CRYPTO_WALLET_TRANSFER",
        currency_type: "INR",
        amount: withdrawAmount,
        mobile_number: mobileNumber ? mobileNumber : "9876543211",
        crypto_currency: selectedCryptoAccount.paymentMethodDetails.cryptoCurrency,
        network_id: selectedCryptoAccount.paymentMethodDetails.networkId,
        wallet_address: selectedCryptoAccount.paymentMethodDetails.walletAddress
      }
      const response = await AGPAY_API.post(
        `/agpay/v2/zenpay-crypto/transactions/:withdraw`,
        payload,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200) {
        toast.success(
          <div>
            <SuccessIcon /> Transaction Saved Successfully!
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setOpenWithdrawModal(false);
        history.push('/my_transactions');
      } else {
        toast.error(
          <div>
            <ErrorIcon /> {'Something went wrong'}
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        <div>
          <ErrorIcon /> {error?.response?.data?.message}
        </div>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setLoading(false);
    }
  };
  


  return (
    <div className="reports-ctn payment-withdraw-ctn">
      {loading ? <Spinner /> : null}
      <div className="header-ctn my-payment-option">
        <IonRow>
          {/* <IonCol sizeMd="1.7" sizeLg="1.7" className="web-view">
          <div className="compt-menu-section">
            <div className="sticky-col">
              <ReportsSideBar />
            </div>
          </div>
        </IonCol> */}
          <IonCol>
            <div className="deposit-form-ctn">

              {
                providersList?.length > 1 && (
                  <Tabs
                    value={tabValue}
                    onChange={(_, newValue) => {
                      if (newValue == 3) {
                        setPaymentOption('CRYPTO_WALLET_TRANSFER');
                        fetchPaymentMethod();
                        getCurrencyList();
                      }
                      setAccountDetails([]);
                      setTabValue(newValue);
                    }}>
                    {
                      providersList?.includes("ABCMONEY") && (
                        <Tab value={0} label="Option 1" />
                      )
                    }
                    {
                      providersList?.includes("PGMAN") && (
                        <Tab value={1} label="Option 2" />
                      )
                    }
                    {
                      providersList?.includes("ZENPAY") && (
                        <Tab value={2} label="Option 3" />
                      )
                    }
                    {
                      providersList?.includes("ZENPAYCRYPTO") && (
                        <Tab value={3} label="Crypto" />
                      )
                    }

                  </Tabs>
                )
              }

              <TabPanel value={tabValue} index={0}>
                <div className="payment-option-title">Choose Payment Option</div>
                <Button
                  className={
                    paymentOption === 'NEFT' ? 'active payment-btn' : 'payment-btn'
                  }
                  onClick={() => setPaymentOption('NEFT')}
                >
                  <img
                    className={'payment-icon'}
                    alt="bank"
                    src={bankIcon}
                    loading="lazy"
                  />
                </Button>
                <div className="account-details-ctn">
                  <Tabs
                    value={1}
                    variant="scrollable"
                    scrollButtons="off"
                    className="actions-list"
                  >
                    <>
                      {accountDetails?.map((acc) => (
                        <>
                          <>
                            <div className="account-btn-ctn">
                              <Button
                                className={
                                  acc?.id?.toString() === selectedAccountId
                                    ? 'active account-detail-withdraw'
                                    : 'account-detail-withdraw'
                                }
                                onClick={() => setSelectedAccountId(acc?.id?.toString())}
                              >
                                <div className="account-name">
                                  {acc?.paymentMethodDetails?.accountHolderName}
                                </div>
                                <div className="account-number">{acc?.paymentMethodDetails?.accountNumber}</div>
                                <div className="account-number">{acc?.paymentMethodDetails?.upiId}</div>
                                <div className="account-number">{acc?.paymentMethodDetails?.phoneNumber}</div>
                                <div className="account-number">{acc?.paymentMethodDetails?.bankName}{acc?.paymentMethodDetails?.bankName ? ',' : null} {acc?.paymentMethodDetails?.branchName}</div>
                                {paymentOption === 'NEFT' && (
                                  <div className="account-ifsc">
                                    IFSC: {acc?.paymentMethodDetails?.ifscCode}
                                  </div>
                                )}
                              </Button>
                              <div className="delete-btn-ctn">
                                <Button className='method-delete-btn'
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    setDeleteId(acc?.id)
                                  }}
                                >
                                  <DeleteForeverOutlined />
                                </Button>
                              </div>
                            </div>
                          </>
                        </>
                      ))}
                    </>
                  </Tabs>
                  {/* {accountDetails?.length > 0 ? (
  <> */}
                  {
                    paymentOption ?
                      <Button
                        title='Add Account'
                        onClick={() => {
                          setAddAccount(true);
                        }}
                        className="add-btn "
                      >
                        <div className="add-account "><Add /> </div>
                      </Button>
                      : null
                  }

                  {/* </> */}
                  {/* ) : null} */}
                </div>

                {
                  selectedAccountId ? (
                    <>
                      <div className="payment-options online-payment-options">
                        <Button
                          className={
                            onlinePaymentOption === 'IMPS' ? 'active payment-btn' : 'payment-btn'
                          }
                          onClick={() => setOnlinePaymentOption('IMPS')}
                        >
                          IMPS
                        </Button>
                        <Button
                          className={
                            onlinePaymentOption === 'NEFT' ? 'active payment-btn' : 'payment-btn'
                          }
                          onClick={() => setOnlinePaymentOption('NEFT')}
                        >
                          NEFT
                        </Button>
                      </div>
                    </>
                  ) : null
                }
                {onlinePaymentOption ? (
                  <>
                    {/* <div className="withdraw-form-divider"></div> */}

                    <form className="account-inputs"
                      onSubmit={(e) => {
                        if (tabValue === 0) {
                          submitOnlinePayment(e);
                        } else if (tabValue == 2) {
                          submitOnlinePayment(e, 'zenpay');
                        } else {
                          submitPayment(e);
                        }
                      }}>
                      <div className="payment-option-title">Enter Payment Details</div>
                      <div className="account-input">
                        <div className="account-label">Enter Mobile Number</div>
                        <NumberFormat
                          required
                          className="acc-input"
                          placeholder="Enter Mobile Number"
                          value={mobileNumber}
                          decimalSeparator={false}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                      </div>
                      <div className="account-input">
                        <div className="account-label">Enter Amount</div>
                        <NumberFormat
                          required
                          className="acc-input"
                          placeholder="Enter Withdraw Amount"
                          value={withdrawAmount}
                          decimalSeparator={false}
                          onChange={(e) => setWithdrawAmount(e.target.value)}
                        />
                      </div>
                      <div className="account-input">
                        <div className="account-label">Enter Notes</div>
                        <TextField
                          required
                          placeholder="Enter Notes"
                          className="acc-input"
                          value={withdrawNotes}
                          onChange={(e) => setWithdrawNotes(e.target.value)}
                        />
                      </div>

                      <Button
                        className="submit-payment-btn"
                        type="submit"
                        disabled={loading ? true : false}
                        endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                      >
                        Submit
                      </Button>
                    </form>
                  </>
                ) : null}
              </TabPanel>

              <TabPanel value={tabValue} index={1} className="payment-options">
                <div className="payment-option-title">Choose Payment Option</div>
                <Button
                  className={
                    paymentOption === 'NEFT' ? 'active payment-btn' : 'payment-btn'
                  }
                  onClick={() => setPaymentOption('NEFT')}
                >
                  <img
                    className={'payment-icon'}
                    alt="bank"
                    src={bankIcon}
                    loading="lazy"
                  />
                </Button>
                <Button
                  className={
                    paymentOption === 'GPAY' ? 'active payment-btn' : 'payment-btn'
                  }
                  onClick={() => setPaymentOption('GPAY')}
                >
                  <img
                    className={'payment-icon'}
                    alt="gpay"
                    src={gpay}
                    loading="lazy"
                  />
                </Button>
                <Button
                  className={
                    paymentOption === 'PHONEPE'
                      ? 'active payment-btn'
                      : 'payment-btn'
                  }
                  onClick={() => setPaymentOption('PHONEPE')}
                >
                  <img
                    className={'payment-icon'}
                    alt="phonepay"
                    src={phonepay}
                    loading="lazy"
                  />
                </Button>
                <Button
                  className={
                    paymentOption === 'PAYTM_WALLET'
                      ? 'active payment-btn'
                      : 'payment-btn'
                  }
                  onClick={() => setPaymentOption('PAYTM_WALLET')}
                >
                  <img
                    className={'payment-icon'}
                    alt="paytm"
                    src={paytm}
                    loading="lazy"
                    height={30}
                  />
                </Button>
                <div>
                  {accountDetails?.length > 0 ? (
                    <>
                      <div className="payment-form-divider"></div>

                      <div className="payment-option-title">
                        Choose Beneficiary Account
                      </div>
                    </>
                  ) : null}
                  <div className="account-details-ctn">
                    <Tabs
                      value={1}
                      variant="scrollable"
                      scrollButtons="off"
                      className="actions-list"
                    >
                      <>
                        {accountDetails?.map((acc) => (
                          <>
                            <>
                              <div className="account-btn-ctn">
                                <Button
                                  className={
                                    acc?.id?.toString() === selectedAccountId
                                      ? 'active account-detail-withdraw'
                                      : 'account-detail-withdraw'
                                  }
                                  onClick={() => setSelectedAccountId(acc?.id?.toString())}
                                >
                                  <div className="account-name">
                                    {acc?.paymentMethodDetails?.accountHolderName}
                                  </div>
                                  <div className="account-number">{acc?.paymentMethodDetails?.accountNumber}</div>
                                  <div className="account-number">{acc?.paymentMethodDetails?.upiId}</div>
                                  <div className="account-number">{acc?.paymentMethodDetails?.phoneNumber}</div>
                                  <div className="account-number">{acc?.paymentMethodDetails?.bankName}{acc?.paymentMethodDetails?.bankName ? ',' : null} {acc?.paymentMethodDetails?.branchName}</div>
                                  {paymentOption === 'NEFT' && (
                                    <div className="account-ifsc">
                                      IFSC: {acc?.paymentMethodDetails?.ifscCode}
                                    </div>
                                  )}
                                </Button>
                                <div className="delete-btn-ctn">
                                  <Button className='method-delete-btn'
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setDeleteId(acc?.id)
                                    }}
                                  >
                                    <DeleteForeverOutlined />
                                  </Button>
                                </div>
                              </div>
                            </>
                          </>
                        ))}
                      </>
                    </Tabs>
                    {
                      paymentOption ?
                        <Button
                          title='Add Account'
                          onClick={() => {
                            setAddAccount(true);
                          }}
                          className="add-btn "
                        >
                          <div className="add-account "><Add /> </div>
                        </Button>
                        : null
                    }
                  </div>
                </div>
                {selectedAccountId ? (
                  <>
                    <div className="withdraw-form-divider"></div>

                    <form className="account-inputs"
                      onSubmit={(e) => {
                        if (tabValue === 0) {
                          submitOnlinePayment(e);
                        } else if (tabValue == 2) {
                          submitOnlinePayment(e, 'zenpay');
                        } else {
                          submitPayment(e);
                        }
                      }}>
                      <div className="payment-option-title">Enter Payment Details</div>
                      <div className="account-input">
                        <div className="account-label">Enter Amount</div>
                        <NumberFormat
                          required
                          className="acc-input"
                          placeholder="Enter Withdraw Amount"
                          value={withdrawAmount}
                          decimalSeparator={false}
                          onChange={(e) => setWithdrawAmount(e.target.value)}
                        />
                      </div>
                      <div className="account-input">
                        <div className="account-label">Enter Notes</div>
                        <TextField
                          required
                          placeholder="Enter Notes"
                          className="acc-input"
                          value={withdrawNotes}
                          onChange={(e) => setWithdrawNotes(e.target.value)}
                        />
                      </div>

                      <Button
                        className="submit-payment-btn"
                        type="submit"
                        disabled={loading ? true : false}
                        endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                      >
                        Submit
                      </Button>
                    </form>
                  </>
                ) : null}

                {paymentOption === 'NEFT' && addAccount && (
                  <form className="account-inputs" onSubmit={(e) => submitDetails(e)}>
                    <div className="payment-option-title">Enter Details</div>
                    <div className="account-input">
                      <div className="account-label">Account Number</div>
                      <NumberFormat
                        required
                        className="acc-input"
                        placeholder="Enter Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        decimalSeparator={false}
                      />
                    </div>
                    <div className="account-input">
                      <div className="account-label">Account Holder Name</div>
                      <TextField
                        required
                        placeholder="Account Holder Name"
                        className="acc-input"
                        value={holderName}
                        onChange={(e) => setHolderName(e.target.value)}
                      />
                    </div>
                    <div className="account-input">
                      <div className="account-label">Bank Name</div>

                      <TextField
                        required
                        placeholder="Bank Name"
                        className="acc-input"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                      />
                    </div>
                    <div className="account-input">
                      <div className="account-label">Branch Name</div>

                      <TextField
                        required
                        placeholder="Branch Name"
                        className="acc-input"
                        value={branchName}
                        onChange={(e) => setBranchName(e.target.value)}
                      />
                    </div>
                    <div className="account-input">
                      <div className="account-label">IFSC Number</div>

                      <TextField
                        required
                        placeholder="Ifsc code"
                        className="acc-input"
                        value={ifscCode}
                        onChange={(e) => setIfscCode(e.target.value)}
                      />
                    </div>

                    <Button
                      className="submit-payment-btn"
                      type="submit"
                      endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                      disabled={loading ? true : false}
                    >
                      ADD
                    </Button>
                  </form>
                )}
                {paymentOption === 'PAYTM_WALLET' && addAccount && (
                  <form className="account-inputs" onSubmit={(e) => submitDetails(e)}>
                    <div className="payment-option-title">Enter Details</div>
                    <div className="account-input">
                      <div className="account-label">Phone Number</div>
                      <NumberFormat
                        required
                        className="acc-input"
                        placeholder="Enter Phone Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        decimalSeparator={false}
                      />
                    </div>
                    <div className="account-input">
                      <div className="account-label">Account Holder Name</div>
                      <TextField
                        required
                        placeholder="Account Holder Name"
                        className="acc-input"
                        value={holderName}
                        onChange={(e) => setHolderName(e.target.value)}
                      />
                    </div>
                    <div className="account-input">
                      <div className="account-label">Display Name</div>

                      <TextField
                        required
                        placeholder="Display Name"
                        className="acc-input"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </div>

                    <Button
                      className="submit-payment-btn"
                      type="submit"
                      endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                      disabled={loading ? true : false}
                    >
                      ADD
                    </Button>
                  </form>
                )}

                {(paymentOption === 'GPAY' || paymentOption === 'PHONEPE') &&
                  addAccount && (
                    <>
                      <div className="withdraw-form-divider"></div>
                      <form className="account-inputs" onSubmit={(e) => submitDetails(e)}>
                        <div className="payment-option-title">Enter UPI Details</div>
                        <div className="account-input">
                          <div className="account-label">Enter UPI ID</div>

                          <TextField
                            required
                            className="acc-input"
                            placeholder="UPI ID"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                          />
                        </div>
                        <div className="account-input">
                          <div className="account-label">Account Holder Name</div>

                          <TextField
                            required
                            placeholder="Account Holder Name"
                            className="acc-input"
                            value={holderName}
                            onChange={(e) => setHolderName(e.target.value)}
                          />
                        </div>
                        <div className="account-input">
                          <div className="account-label">Display Name</div>

                          <TextField
                            required
                            placeholder="Display Name"
                            className="acc-input"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                          />
                        </div>

                        <Button
                          className="submit-payment-btn"
                          type="submit"
                          endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                          disabled={loading ? true : false}
                        >
                          ADD
                        </Button>
                      </form>
                    </>
                  )}
              </TabPanel>

              {providersList.includes('ZENPAY') && (
                <TabPanel value={tabValue} index={2}>
                  <div className="payment-option-title">Choose Payment Option</div>
                  <Button
                    className={
                      paymentOption === 'NEFT'
                        ? 'active payment-btn'
                        : 'payment-btn'
                    }
                    onClick={() => setPaymentOption('NEFT')}
                  >
                    <img
                      className={'payment-icon'}
                      alt="bank"
                      src={bank}
                      loading="lazy"
                    />
                  </Button>
                  <div className="account-details-ctn">
                    <Tabs
                      value={1}
                      variant="scrollable"
                      scrollButtons="off"
                      className="actions-list"
                    >
                      <>
                        {accountDetails?.map((acc) => (
                          <>
                            <>
                              <div className="account-btn-ctn">
                                <Button
                                  className={
                                    acc?.id?.toString() === selectedAccountId
                                      ? 'active account-detail-withdraw'
                                      : 'account-detail-withdraw'
                                  }
                                  onClick={() =>
                                    setSelectedAccountId(acc?.id?.toString())
                                  }
                                >
                                  <div className="account-name">
                                    {acc?.paymentMethodDetails?.accountHolderName}
                                  </div>
                                  <div className="account-number">
                                    {acc?.paymentMethodDetails?.accountNumber}
                                  </div>
                                  <div className="account-number">
                                    {acc?.paymentMethodDetails?.upiId}
                                  </div>
                                  <div className="account-number">
                                    {acc?.paymentMethodDetails?.phoneNumber}
                                  </div>
                                  <div className="account-number">
                                    {acc?.paymentMethodDetails?.bankName}
                                    {acc?.paymentMethodDetails?.bankName
                                      ? ','
                                      : null}{' '}
                                    {acc?.paymentMethodDetails?.branchName}
                                  </div>
                                  {paymentOption === 'NEFT' && (
                                    <div className="account-ifsc">
                                      IFSC: {acc?.paymentMethodDetails?.ifscCode}
                                    </div>
                                  )}
                                </Button>
                                <div className="delete-btn-ctn">
                                  <Button
                                    className="method-delete-btn"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setDeleteId(acc?.id);
                                    }}
                                  >
                                    <DeleteForeverOutlined />
                                  </Button>
                                </div>
                              </div>
                            </>
                          </>
                        ))}
                      </>
                    </Tabs>
                    {paymentOption ? (
                      <Button
                        title="Add Account"
                        onClick={() => {
                          setAddAccount(true);
                        }}
                        className="add-btn "
                      >
                        <div className="add-account ">
                          <Add />{' '}
                        </div>
                      </Button>
                    ) : null}

                  </div>
                  {paymentOption === 'NEFT' && addAccount && (
                    <form
                      className="account-inputs"
                      onSubmit={(e) => submitDetails(e)}
                    >
                      <div className="payment-option-title">Enter Details</div>
                      <div className="account-input">
                        <div className="account-label">Account Number</div>
                        <NumberFormat
                          required
                          className="acc-input"
                          placeholder="Enter Account Number"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          decimalSeparator={false}
                        />
                      </div>
                      <div className="account-input">
                        <div className="account-label">Account Holder Name</div>
                        <TextField
                          required
                          placeholder="Account Holder Name"
                          className="acc-input"
                          value={holderName}
                          onChange={(e) => setHolderName(e.target.value)}
                        />
                      </div>
                      <div className="account-input">
                        <div className="account-label">Bank Name</div>

                        <TextField
                          required
                          placeholder="Bank Name"
                          className="acc-input"
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                        />
                      </div>
                      <div className="account-input">
                        <div className="account-label">Branch Name</div>

                        <TextField
                          required
                          placeholder="Branch Name"
                          className="acc-input"
                          value={branchName}
                          onChange={(e) => setBranchName(e.target.value)}
                        />
                      </div>
                      <div className="account-input">
                        <div className="account-label">IFSC Number</div>

                        <TextField
                          required
                          placeholder="Ifsc code"
                          className="acc-input"
                          value={ifscCode}
                          onChange={(e) => setIfscCode(e.target.value)}
                        />
                      </div>

                      <Button
                        className="submit-payment-btn"
                        type="submit"
                        endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                        disabled={loading ? true : false}
                      >
                        ADD
                      </Button>
                    </form>
                  )}
                  {selectedAccountId ? (
                    <>
                      <form
                        className="account-inputs"
                        onSubmit={(e) => {
                          if (tabValue === 2) {
                            submitZenpayPayment(e);
                          }
                        }}
                      >
                        <div className="payment-option-title">
                          Enter Payment Details
                        </div>
                        <div className="account-input">
                          <div className="account-label">Enter Amount</div>
                          <NumberFormat
                            required
                            className="acc-input"
                            placeholder="Enter Withdraw Amount"
                            value={withdrawAmount}
                            decimalSeparator={false}
                            onChange={(e) => setWithdrawAmount(e.target.value)}
                          />
                        </div>
                        <div className="account-input">
                          <div className="account-label">Enter Notes</div>
                          <TextField
                            required
                            placeholder="Enter Notes"
                            className="acc-input"
                            value={withdrawNotes}
                            onChange={(e) => setWithdrawNotes(e.target.value)}
                          />
                        </div>

                        <Button
                          className="submit-payment-btn"
                          type="submit"
                          disabled={loading ? true : false}
                          endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                        >
                          Submit
                        </Button>
                      </form>
                    </>
                  ) : null}
                </TabPanel>
              )}

              {providersList.includes('ZENPAYCRYPTO') && (
                <TabPanel value={tabValue} index={3}>
                  <div className="account-details-ctn">
                    <Tabs
                      value={1}
                      variant="scrollable"
                      scrollButtons="off"
                      className="actions-list"
                    >
                      <>
                        {accountDetails?.map((acc) => (
                          <>
                            <>
                              <div className="account-btn-ctn">
                                <Button
                                  className={
                                    acc?.id?.toString() === selectedCrypto.id?.toString()
                                      ? 'active account-detail-withdraw'
                                      : 'account-detail-withdraw'
                                  }
                                  onClick={() =>
                                    setSelectedCryptoAccount(acc)
                                  }
                                >
                                  <div className="account-name">
                                    {acc?.paymentMethodDetails?.cryptoCurrency + ' (' + acc?.paymentMethodDetails?.blockchain + ')'}
                                  </div>
                                  <div className="account-number">
                                    {acc?.paymentMethodDetails?.walletAddress}
                                  </div>
                                  
                                </Button>
                                <div className="delete-btn-ctn">
                                  <Button
                                    className="method-delete-btn"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setDeleteId(acc?.id);
                                    }}
                                  >
                                    <DeleteForeverOutlined />
                                  </Button>
                                </div>
                              </div>
                            </>
                          </>
                        ))}
                      </>
                    </Tabs>
                    {paymentOption ? (
                      <Button
                        title="Add Account"
                        onClick={() => {
                          setAddAccount(true);
                        }}
                        className="add-btn "
                      >
                        <div className="add-account ">
                          <Add />{' '}
                        </div>
                      </Button>
                    ) : null}

                  </div>
                  {paymentOption === 'CRYPTO_WALLET_TRANSFER' && addAccount && (
                    <form
                      className="account-inputs"
                      onSubmit={(e) => submitDetails(e)}
                    >
                      <div className="select-template">
                        <div className="st-label">Select Currency</div>
                        <Select
                          value={selectedCrypto}
                          // disabled={amountToBePaid ? true : false}
                          onChange={(e: any) => setSelectedCrypto(e.target.value)}
                          className="select-compo"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          {currencyList.map((indv) => (
                            <MenuItem key={indv.crypto_currency} value={indv}>
                              {indv.crypto_currency + ' (' + indv.blockchain + ')'}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div className="account-input">
                        <div className="account-label">Wallet Address</div>
                        <TextField
                          required
                          placeholder="Wallet Address"
                          className="acc-input"
                          value={walletAddress}
                          onChange={(e) => setWalletAddress(e.target.value)}
                        />
                      </div>

                      <Button
                        className="submit-payment-btn"
                        type="submit"
                        endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                        disabled={loading ? true : false}
                      >
                        Next
                      </Button>
                    </form>
                  )}
                  {selectedCryptoAccount ? (
                    <>
                      <form
                        className="account-inputs"
                        onSubmit={(e) => {
                          if (tabValue === 3) {
                            submitZenpayCryptoPayment(e);
                          }
                        }}
                      >
                        <div className="payment-option-title">
                          Enter Payment Details
                        </div>
                        <div className="account-input">
                          <div className="account-label">Enter Amount</div>
                          <NumberFormat
                            required
                            className="acc-input"
                            placeholder="Enter Withdraw Amount"
                            value={withdrawAmount}
                            decimalSeparator={false}
                            disabled = {recievableAmount ? true : false}
                            onChange={(e) => setWithdrawAmount(e.target.value)}
                          />
                        </div>
                        {/* <div className="account-input">
                          <div className="account-label">Enter Notes</div>
                          <TextField
                            required
                            placeholder="Enter Notes"
                            className="acc-input"
                            value={withdrawNotes}
                            onChange={(e) => setWithdrawNotes(e.target.value)}
                          />
                        </div> */}

                       {!recievableAmount && <Button
                          className="submit-payment-btn"
                          // type="submit"
                          disabled={loading ? true : false}
                          endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                          onClick={() => getReceivableAmount()}
                        >
                          Next
                        </Button>}


                        {recievableAmount && 
                        <>
                          <div className="account-input">
                            <div className="account-label">Receivable Amount</div>
                            <TextField
                              required
                              placeholder="Enter Notes"
                              className="acc-input"
                              disabled
                              value={recievableAmount}
                            />
                          </div>

                          <Button
                            className="submit-payment-btn"
                            type="submit"
                            disabled={loading ? true : false}
                            endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
                          >
                            Submit
                          </Button>
                        </>
                        }


                      </form>
                    </>
                  ) : null}
                </TabPanel>
              )}
            </div>
          </IonCol>
        </IonRow>
      </div>

      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        className="payment-method-confirm"
      >
        <div className="dialog-content">
          {/* <div className="void-bet-text">{getDisplayName(betText)}</div> */}
          <div className='payment-text-bold'>This account will be deleted!</div>
          <div>Confirm your action to delete this account</div>
        </div>
        <div className="dialog-footer">
          <Button
            color="primary"
            className="footer-action-btn"
            onClick={() => {
              deletePaymentMethod();
              setShowDeleteModal(false);
            }}
          >
            Yes, Confirm
          </Button>
          <Button
            color="primary"
            className="footer-action-btn"
            onClick={() => {
              setShowDeleteModal(false);
              setDeleteId(null)
            }}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setOpenWithdrawModal: (val) => dispatch(setOpenWithdrawModal(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal);
