import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonRow, IonSpinner } from '@ionic/react';
import { IonLabel } from '@ionic/react';
import './ForgotPassword.scss';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import HorizontalLabelPositionBelowStepper from '../../pages/SignUp/Steps';
import webtitleImg from '../../assets/images/brand/title.png';
import { ReactComponent as TickMark } from '../../assets/images/icons/Isolation_Mode.svg';
import { ReactComponent as Stars } from '../../assets/images/icons/starts.svg';
import SVLS_API from '../../svls-api';
import { useHistory } from 'react-router';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import logo from "../../assets/images/brand/title.png";

const ForgotPwdForm = () => {
  const [option, setOption] = useState('Username');
  const [progress, setProgress] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [disable, setDisable] = useState(true);
  const history = useHistory();

  const sendOtpFormik = useFormik({
    initialValues: { username: '', phonenumber: '' },
    validationSchema: Yup.object(() =>
      option === 'Username'
        ? {
            username: Yup.string()
              .required('Required')
              .min(4)
              .max(20)
              .trim()
              .matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
          }
        : { phonenumber: Yup.string().required('Required') }
    ),
    onSubmit: (values) => {
      setErrorMsg(null);
      sendOtp();
    },
  });

  const resetPasswordFormik = useFormik({
    initialValues: { otp: '', newPassword: '', confirmPassword: '' },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required('Required')
        .trim()
        .length(6)
        .matches(/^[0-9]+$/, 'Only numbers are allowed'),
      newPassword: Yup.string().required('Required').trim(),
      confirmPassword: Yup.string()
        .required('Required')
        .test(
          'Confirm password check',
          'Password mismatch',
          function confirmPasswordCheck(confirmPassword) {
            return confirmPassword === this.parent.newPassword;
          }
        ),
    }),
    onSubmit: (values) => {
      setErrMsg(null);

      const resetPwdReq = {
        resetPassword: values.newPassword,
      };
      resetPassword(sendOtpFormik.values.username, values.otp, resetPwdReq);
    },
  });

  const resetPassword = async (
    username: string,
    otp: string,
    request: ResetPasswordRequest
  ) => {
    try {
      const username = sendOtpFormik.values.username;
      const phoneNumber = sendOtpFormik.values.phonenumber;
      setLoading(true);
      const response = await SVLS_API.put(
        `/account/v2/users/${
          option === 'Username' ? username : phoneNumber
        }/password:resetOnline${
          option === 'Username' ? '' : '?userIdentifierType=PHONE_NUMBER'
        }`,
        request,
        {
          params: {
            otp: otp,
          },
        }
      );
      toast.success('Password updated successfully');
      history.push('/home');
    } catch (err) {
      console.log(err);

      setErrMsg(err.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const sendOtp = async () => {
    try {
      const username = sendOtpFormik.values.username;
      const phoneNumber = sendOtpFormik.values.phonenumber;
      setProgress(true);
      let response = await SVLS_API.post(
        `/account/v2/users/${
          option === 'Username' ? username : phoneNumber
        }/:sendResetPasswordOtp${
          option !== 'Username' ? '?userIdentifierType=PHONE_NUMBER' : ''
        }`
      );
      if (response.status === 204) {
        setDisable(false);
      }
    } catch (err) {
      setErrorMsg(err.response?.data?.message);
    } finally {
      setProgress(false);
    }
  };

  return (
    <>
      <form
        onSubmit={sendOtpFormik.handleSubmit}
        className="forgot-pwd-form-ctn"
        autoComplete="off"
      >
        <img className="logo" src={logo} />
        <div className="sign-in-title">Forgot password</div>
        <div className="select-input .pwd-field">
          <Select
            className="option-selection"
            value={option}
            onChange={(e) => {
              setOption(e.target.value.toString());
              sendOtpFormik.setFieldValue('username', '');
            }}
          >
            <MenuItem value="Username">User ID</MenuItem>
            <MenuItem value="Phone number">Mobile</MenuItem>
          </Select>
          <div>
            {option === 'Username' ? (
              <TextField
                className="login-input-field text-field"
                type="text"
                name="username"
                placeholder="Enter username"
                variant="outlined"
                autoFocus
                error={
                  sendOtpFormik.touched.username &&
                  sendOtpFormik.errors.username
                    ? true
                    : false
                }
                helperText={
                  sendOtpFormik.touched.username &&
                  sendOtpFormik.errors.username
                    ? sendOtpFormik.errors.username
                    : null
                }
                {...sendOtpFormik.getFieldProps('username')}
              />
            ) : (
              <div className="text-field">
                <PhoneInput
                  inputClass="number-field"
                  country={'in'}
                  placeholder="Enter phone number"
                  value={sendOtpFormik.values.phonenumber}
                  onChange={(value, country, e, formattedValue) => {
                    sendOtpFormik.setFieldValue('phonenumber', value);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <span className="error-msg">{errorMsg}</span>

        <Button
          className="confirm-btn send-btn"
          color="primary"
          type="submit"
          endIcon={progress ? <IonSpinner name="lines-small" /> : ''}
          variant="contained"
          disabled={!sendOtpFormik.isValid}
        >
          Send Otp
        </Button>
      </form>
      <form
        className="forgot-pwd-form-ctn"
        onSubmit={resetPasswordFormik.handleSubmit}
        autoComplete="off"
      >
        <div className="usr-input">
          {/* <IonLabel className=".pwd-field">
          Otp<span className="required">*</span>
        </IonLabel> */}
          <TextField
            className="login-input-field user-name"
            placeholder="Enter OTP"
            type="text"
            name="otp"
            variant="outlined"
            autoFocus
            // disabled={disable}
            error={
              resetPasswordFormik.touched.otp && resetPasswordFormik.errors.otp
                ? true
                : false
            }
            helperText={
              resetPasswordFormik.touched.otp && resetPasswordFormik.errors.otp
                ? resetPasswordFormik.errors.otp
                : null
            }
            {...resetPasswordFormik.getFieldProps('otp')}
          />
        </div>

        <div className="usr-input">
          {/* <IonLabel className="input-label">
          {langData[langSelected]['900044']}
          <span className="required">*</span>
        </IonLabel> */}
          <FormControl
            className="login-input-field pwd-field"
            variant="outlined"
            error={
              resetPasswordFormik.touched.newPassword &&
              resetPasswordFormik.errors.newPassword
                ? true
                : false
            }
          >
            <OutlinedInput
              id="standard-adornment-password"
              type={showNewPassword ? 'text' : 'password'}
              name="newPassword"
              // disabled={disable}
              placeholder="Enter new password"
              {...resetPasswordFormik.getFieldProps('newPassword')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {resetPasswordFormik.touched.newPassword &&
            resetPasswordFormik.errors.newPassword ? (
              <FormHelperText error id="my-helper-text">
                {resetPasswordFormik.errors.newPassword}
              </FormHelperText>
            ) : null}
          </FormControl>
        </div>

        <div className="usr-input">
          {/* <IonLabel className="input-label">
          {langData[langSelected]['900045']}
          <span className="required">*</span>
        </IonLabel> */}
          <FormControl
            className="login-input-field pwd-field"
            variant="outlined"
            error={
              resetPasswordFormik.touched.confirmPassword &&
              resetPasswordFormik.errors.confirmPassword
                ? true
                : false
            }
          >
            <OutlinedInput
              id="standard-adornment-password"
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              // disabled={disable}
              placeholder="Enter confirm password"
              {...resetPasswordFormik.getFieldProps('confirmPassword')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    {showConfirmPassword ? (
                      <Visibility className="no-margin" />
                    ) : (
                      <VisibilityOff className="no-margin" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {resetPasswordFormik.touched.confirmPassword &&
            resetPasswordFormik.errors.confirmPassword ? (
              <FormHelperText error id="my-helper-text">
                {resetPasswordFormik.errors.confirmPassword}
              </FormHelperText>
            ) : null}
          </FormControl>
        </div>

        <span className="error-msg">{errMsg}</span>

        <Button
          className="confirm-btn reset-btn"
          color="primary"
          endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
          type="submit"
          variant="contained"
          disabled={disable || !resetPasswordFormik.isValid}
        >
          Reset password
        </Button>
      </form>
      {/* <IonRow className="msg-row">
        <div className="back-home">
          <NavLink to="/home">Back to home</NavLink>
        </div>
      </IonRow> */}
    </>
  );
}

type ResetPasswordRequest = {
  resetPassword: string;
};

export default ForgotPwdForm;
